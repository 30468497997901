@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
@import url("https://fonts.googleapis.com/css?family=Montserrat%3A300%2C400%2C500%2C600%2C700");
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*
 * Columnizer
 */
/*
 * Vertical Alignment Mixin
 */
/*
 * Vertical Alignment Mixin for IE9
 */
/*
 * Cross Browser Vertical Centering of Section
 */
.council-members__infobox .plus, .news-listing a.news-listing__box .arrow:after {
  background: #ce251e;
  background: -moz-linear-gradient(-45deg, #ce251e 0%, #ce261e 16%, #e05120 29%, #ec6821 49%, #f37121 73%, #f37121 100%);
  background: -webkit-linear-gradient(-45deg, #ce251e 0%, #ce261e 16%, #e05120 29%, #ec6821 49%, #f37121 73%, #f37121 100%);
  background: linear-gradient(135deg, #ce251e 0%, #ce261e 16%, #e05120 29%, #ec6821 49%, #f37121 73%, #f37121 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce251e', endColorstr='#f37121',GradientType=1 ); }

.council-members__infobox:hover .plus, .news-listing a.news-listing__box .arrow:before {
  background: #fcaf17;
  background: -moz-linear-gradient(-45deg, #fcaf17 0%, #f5a619 19%, #ea911b 47%, #e4871b 62%, #c47415 100%);
  background: -webkit-linear-gradient(-45deg, #fcaf17 0%, #f5a619 19%, #ea911b 47%, #e4871b 62%, #c47415 100%);
  background: linear-gradient(135deg, #fcaf17 0%, #f5a619 19%, #ea911b 47%, #e4871b 62%, #c47415 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcaf17', endColorstr='#c47415',GradientType=1 ); }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/*
 * Colors
 *
 * Add, Remove or Edit any of the colors
 */
/*
 * ! ! ! WARNING ! ! !
 * DO NOT remove any line below here. Only adjust the values.
 */
/*
 * Style Colors
 */
/*
 * ! ! ! WARNING ! ! !
 * DO NOT remove any line below here. Only adjust the values.
 */
/*
 * Header Styles
 */
/*
 *  Logo Style
 */
/*
 * Nav Styles
 */
/*
 * Slider Styles
 */
/*
 * Button Styles
 */
/*
 * Button Alternate Styles
 */
/*
 * Inputs
 */
/*
 * Divider Thickness
 */
/*
 * Spacing
 */
/*
 * Banner
 */
/*
 * Map
 */
/*
 * Line height
 */
/*
 * Default Animation Values
 */
/*
 * ! ! ! WARNING ! ! !
 * DO NOT remove any line below here. Only adjust the values.
 */
/*
 * Font Importing
 */
/*
 * Font Styles
 */
/*
 * Element Font Usage
 */
header nav .nav__wrapper a, header nav .nav__wrapper a.button {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0em; }

footer nav > * a, footer nav > * a.button, .footer .footer__wrapper > * {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0em; }

.banner .banner__wrapper h1, .member-page .member-page__content h1 {
  font-family: "Montserrat", serif;
  font-size: 4.0625rem;
  font-weight: 300;
  letter-spacing: 0em; }
  @media screen and (max-width: 65.5625em) {
    .banner .banner__wrapper h1, .member-page .member-page__content h1 {
      font-size: 1.75rem; } }

.intro .intro__wrapper h1, .news-article h1 {
  font-family: "Montserrat", serif;
  font-size: 4.0625rem;
  font-weight: 300;
  line-height: 1.1538;
  letter-spacing: 0em; }
  @media screen and (max-width: 65.5625em) {
    .intro .intro__wrapper h1, .news-article h1 {
      font-size: 1.75rem; } }

.intro .intro__copy, .member-page .member-page__title, .news-article .news-article__intro-copy {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.5625rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0em; }
  @media screen and (max-width: 65.5625em) {
    .intro .intro__copy, .member-page .member-page__title, .news-article .news-article__intro-copy {
      font-size: 1rem; } }

.callout .callout__wrapper h1 {
  font-family: "Montserrat", serif;
  font-size: 2.625rem;
  font-weight: 500;
  letter-spacing: 0em; }
  @media screen and (max-width: 65.5625em) {
    .callout .callout__wrapper h1 {
      font-size: 1.75rem; } }

.copy .copy__wrapper h1, .cta .cta__wrapper h1, .image .image__wrapper h1, .feed .feed__wrapper .feed__intro h1, .contact-form .copy__wrapper h1, .signup .signup__wrapper h1, .media-footer h2.media-footer__headline {
  font-family: "Montserrat", serif;
  font-size: 1.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em; }
  @media screen and (max-width: 65.5625em) {
    .copy .copy__wrapper h1, .cta .cta__wrapper h1, .image .image__wrapper h1, .feed .feed__wrapper .feed__intro h1, .contact-form .copy__wrapper h1, .signup .signup__wrapper h1, .media-footer h2.media-footer__headline {
      font-size: 1.5rem; } }

.image .image__wrapper h2 {
  font-family: "Montserrat", serif;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.feed .feed__wrapper h1 {
  font-family: "Montserrat", serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0em; }

.quote .quote__wrapper {
  font-family: "Montserrat", serif;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em; }
  @media screen and (max-width: 65.5625em) {
    .quote .quote__wrapper {
      font-size: 1.75rem; } }

.quote .quote__wrapper footer {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1em; }

figure figcaption, .copy .copy__gallery figure figcaption {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0em; }

.button, .split-view.split-initialized figure:nth-of-type(1):before, .split-view.split-initialized figure:nth-of-type(2):before {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.copy, .member-page .health-system__description {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.875; }

.large-copy-text, .image .image__copy, .feed .feed__wrapper .feed__intro, .form-block .form-block__copy, .member-page .member-page__content, .news-article .news-article__copy, .media-footer .media-footer__about {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2; }
  @media screen and (max-width: 65.5625em) {
    .large-copy-text, .image .image__copy, .feed .feed__wrapper .feed__intro, .form-block .form-block__copy, .member-page .member-page__content, .news-article .news-article__copy, .media-footer .media-footer__about {
      font-size: 1rem;
      line-height: 1.875; } }

.callout .callout__wrapper {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.5625rem;
  font-weight: 300;
  line-height: 1.6; }
  @media screen and (max-width: 65.5625em) {
    .callout .callout__wrapper {
      font-size: 1.25rem;
      line-height: 1.875; } }

.council-members a.council-members__item small, .news-listing .news-listing__item .meta, .member-page .health-system__details .location, .news-article .news-article__details .news-date, .news-article .news-article__details .news-type {
  font-size: 0.75rem;
  line-height: 1.58333;
  text-transform: uppercase;
  color: #808080;
  letter-spacing: 0.2em; }

.news-article .news-article__copy a, .media-footer .media-footer__about a, .media-contact .number span a, .media-contact .email a {
  font-weight: 600;
  color: #ce251e;
  border-bottom: 2px solid #ffc400;
  -webkit-transition: border-bottom-color 250ms;
  -moz-transition: border-bottom-color 250ms;
  transition: border-bottom-color 250ms; }
  .news-article .news-article__copy a:visited, .media-footer .media-footer__about a:visited, .media-contact .number span a:visited, .media-contact .email a:visited {
    color: #ce251e; }
  .news-article .news-article__copy a:hover, .media-footer .media-footer__about a:hover, .media-contact .number span a:hover, .media-contact .email a:hover, .news-article .news-article__copy a:active, .media-footer .media-footer__about a:active, .media-contact .number span a:active, .media-contact .email a:active {
    border-bottom: 2px solid #ce251e; }

@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello.eot?13976397");
  src: url("../fonts/fontello.eot?13976397#iefix") format("embedded-opentype"), url("../fonts/fontello.woff2?13976397") format("woff2"), url("../fonts/fontello.woff?13976397") format("woff"), url("../fonts/fontello.ttf?13976397") format("truetype"), url("../fonts/fontello.svg?13976397#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?13976397#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
@-webkit-keyframes delayedFadeIn {
  0% {
    opacity: 0; }
  90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes delayedFadeIn {
  0% {
    opacity: 0; }
  90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes delayedFadeIn {
  0% {
    opacity: 0; }
  90% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    display: block;
    opacity: 1; }
  100% {
    display: block;
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    display: block;
    opacity: 1; }
  100% {
    display: block;
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1; }
  100% {
    display: block;
    opacity: 0; } }

@-webkit-keyframes fadeOutDelayed {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOutDelayed {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOutDelayed {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

html {
  -webkit-tap-highlight-color: transparent; }

.loading {
  display: none !important; }
  .loading .loading-logo {
    height: 67px; }

abbr[title] {
  text-decoration: none;
  border-bottom: none; }

blockquote {
  margin: 0; }

p + .button {
  margin-top: 1em; }

img {
  max-width: 100%; }
  img:not(.header-logo):not(.footer-logo) {
    height: auto; }

figure {
  margin: 0; }
  figure img {
    display: block; }
  figure figcaption {
    color: #000; }

article.featured {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  article.featured::after {
    clear: both;
    content: "";
    display: table; }
  article.featured > *:not(style):not(script):nth-child(2n+1) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%; }
    article.featured > *:not(style):not(script):nth-child(2n+1):last-child {
      margin-right: 0; }
  article.featured > *:not(style):not(script):nth-child(2n+2) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%; }
    article.featured > *:not(style):not(script):nth-child(2n+2):last-child {
      margin-right: 0; }
  article.featured > *:not(style):not(script):nth-child(2n) {
    margin-right: 0; }
  article.featured > *:not(style):not(script):nth-child(2n+1) {
    clear: both; }
  article.featured > *:not(style):not(script):last-child {
    margin-bottom: 0; }
  article.featured figure img {
    border-radius: 12px; }

article > :first-child {
  margin-top: 0; }

article > :last-child {
  margin-bottom: 0; }

div.button-container + div.button-container {
  margin-top: 15px; }

.button {
  line-height: 1.2;
  display: inline-block;
  color: #000;
  border: 0 solid #fff;
  border-radius: 36px;
  background-color: #fff;
  padding: 21px 58px;
  margin-right: 8px;
  text-transform: uppercase; }
  .button + .button {
    margin-top: 1em; }
  .button:hover {
    color: #000;
    border-color: #ce251e;
    background-color: #ce251e; }
  .button.button--alt, .contact-form .copy__wrapper form .button, .contact-form.form--alt-bg form .button {
    color: #000;
    border: 0 solid #ffc400;
    border-radius: 36px;
    background-color: #ffc400;
    text-transform: uppercase; }
    .button.button--alt:hover, .contact-form .copy__wrapper form .button:hover, .contact-form.form--alt-bg form .button:hover {
      color: #000;
      border-color: #ce251e;
      background-color: #ce251e; }

.divider {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  .divider::after {
    clear: both;
    content: "";
    display: table; }
  .divider > *:not(style):not(script):nth-child(1n+1) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%; }
    .divider > *:not(style):not(script):nth-child(1n+1):last-child {
      margin-right: 0; }
  .divider > *:not(style):not(script):nth-child(1n+1) {
    margin-right: 0; }
  .divider > *:not(style):not(script):nth-child(1n) {
    margin-right: 0; }
  .divider > *:not(style):not(script):nth-child(1n+1) {
    clear: both; }
  .divider > *:not(style):not(script):last-child {
    margin-bottom: 0; }
  .divider hr {
    border: none;
    border-top: 6px solid #ffc400;
    margin: 0; }

a[x-apple-data-detectors] {
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  text-decoration: none !important;
  color: inherit !important; }

.bg-color-lightGray {
  background: #f4f4f4; }

.bg-color-white {
  background: #fff; }

.bg-color-black {
  background: #000; }

.bg-color-yellow {
  background: #ffc400; }

.bg-color-red {
  background: #ce251e; }

.text-color-red {
  color: #ce251e; }

.text-color-black {
  color: #000; }

.text-color-white {
  color: #fff; }

.divider-color-white:after {
  border-bottom-color: #fff !important; }

.divider-color-yellow:after {
  border-bottom-color: #ffc400 !important; }

.divider-color-black:after {
  border-bottom-color: #000 !important; }

body {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: normal;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none;
  -webkit-transition: color 250ms ease, border 250ms ease;
  -moz-transition: color 250ms ease, border 250ms ease;
  transition: color 250ms ease, border 250ms ease; }

main a {
  font-weight: 600; }
  main a:not(.button) {
    color: #3680bf;
    border-bottom: 2px solid transparent; }
    main a:not(.button):hover {
      color: #ce251e;
      border-bottom: 2px solid #ce251e; }
    main a:not(.button):visited {
      color: #c5c6c5; }

main ul:not(.slick-dots):not(.dk-select-options) {
  padding-left: 1.5em;
  list-style: none; }
  main ul:not(.slick-dots):not(.dk-select-options) li:before {
    display: block;
    float: left;
    margin-left: -1em;
    content: '•';
    color: #ce251e; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0 0 1em; }
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }

.fonts-loading h1,
.fonts-loading h2,
.fonts-loading h3,
.fonts-loading h4,
.fonts-loading h5,
.fonts-loading h6 {
  font-family: serif; }

.fonts-loading p,
.fonts-loading li,
.fonts-loading a,
.fonts-loading span,
.fonts-loading td,
.fonts-loading time,
.fonts-loading .menu-toggle {
  font-family: Arial, Helvetica, sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", serif;
  line-height: 1.2;
  color: #ce251e; }

p,
li,
a,
span,
td,
time,
.menu-toggle {
  font-family: "Montserrat", Arial, Helvetica, sans-serif; }

.intro .intro__wrapper h1, .news-article h1 {
  margin-bottom: 25px; }
  .intro .intro__wrapper h1:after, .news-article h1:after {
    display: block;
    clear: both;
    width: 45%;
    padding-top: 25px;
    content: '';
    border-bottom: 6px solid #ffc400;
    margin-right: auto;
    margin-bottom: 25px;
    margin-left: auto; }

.feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item:not(.feed__item--image):not(:last-child), .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image:not(:last-child) {
  margin-bottom: 21px; }
  .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item:not(:last-child):not(.feed__item--image):after, .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image:not(:last-child):after {
    display: block;
    clear: both;
    width: 100%;
    padding-top: 21px;
    content: '';
    border-bottom: 6px solid #ffc400;
    margin-right: auto;
    margin-left: auto; }

header {
  border-bottom: 21px solid #fff; }
  header .header__wrapper {
    position: relative;
    padding: 2em 2em;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    header .header__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    header .header__wrapper .header-logo {
      display: block;
      height: 60px;
      margin: 0; }

header {
  background-color: #f4f4f4; }
  @media screen and (min-width: 65.5625em) {
    header nav {
      right: 2em;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); } }
  header .menu-toggle {
    display: none;
    outline: none; }
  header nav .nav__wrapper {
    text-transform: uppercase; }
    header nav .nav__wrapper > * {
      padding-right: 0;
      padding-left: 0; }
    header nav .nav__wrapper a {
      margin-right: 18px;
      margin-left: 18px; }
      header nav .nav__wrapper a:not(.button) {
        color: #000; }
        header nav .nav__wrapper a:not(.button):hover {
          color: #ce251e; }
        header nav .nav__wrapper a:not(.button).active {
          color: #ce251e; }
      header nav .nav__wrapper a.button {
        padding: 8px 12px; }
    header nav .nav__wrapper .nav-item {
      display: inline-block; }
      header nav .nav__wrapper .nav-item:last-child a {
        margin-right: 0; }

footer nav > * {
  display: inline-block;
  margin-right: 29px;
  margin-left: 29px; }
  footer nav > * a {
    color: #fff; }
    footer nav > * a:hover {
      color: #ce251e; }
    footer nav > * a.button {
      padding: 8px 12px; }

.footer {
  background-color: #000; }
  .footer .footer__wrapper {
    text-align: center;
    padding: 127px 2em 238px; }
    .footer .footer__wrapper > * {
      list-style: none;
      text-align: center;
      color: #fff; }
      .footer .footer__wrapper > *:nth-child(3n+2) {
        padding-top: 0;
        padding-bottom: 0; }
      .footer .footer__wrapper > * > span {
        display: inline-block;
        margin-right: 8px;
        margin-left: 8px; }
      .footer .footer__wrapper > *:not(style):not(script):nth-child(1n) {
        display: inline-block;
        float: none;
        vertical-align: middle; }
      .footer .footer__wrapper > * .icon-phone {
        color: #ce251e; }
        .footer .footer__wrapper > * .icon-phone:before {
          margin-left: -15px;
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .footer .footer__wrapper > * .copyright {
        font-size: 0.75rem;
        margin-top: 1em;
        margin-bottom: -2em; }
    .footer .footer__wrapper .footer-logo {
      height: 50px; }
  .footer .footer-social {
    display: block;
    width: 100%;
    margin-top: 80px;
    text-align: center; }
    .footer .footer-social::after {
      clear: both;
      content: "";
      display: table; }
    .footer .footer-social img {
      width: 28px;
      height: 28px;
      margin: 0 6px; }

a.social {
  font-size: 18px;
  margin-right: 2px;
  margin-left: 2px;
  padding-right: 2px;
  padding-left: 2px;
  color: #fff; }
  a.social:first-child {
    margin-left: 6px; }
  a.social:before {
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    transition: all 250ms ease; }
  a.social.facebook:before {
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    content: "";
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  a.social.facebook:hover:before {
    color: #3b5998; }
  a.social.twitter:before {
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    content: "";
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  a.social.twitter:hover:before {
    color: #00aced; }
  a.social.linkedin:before {
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    content: "";
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  a.social.linkedin:hover:before {
    color: #0077b5; }

.slick-slider .slick-slide,
.slick-slider button {
  outline: none; }

.slick-slider .slick-list {
  padding-bottom: 45px; }
  .slick-slider .slick-list figcaption {
    max-height: 32px;
    margin-top: 16px;
    text-align: center; }

.slick-slider .slick-dots {
  width: 100%;
  list-style: none;
  text-align: center;
  opacity: .75;
  position: absolute;
  bottom: 60px;
  margin: 0;
  padding: 0;
  -webkit-transition: opacity 250ms ease;
  -moz-transition: opacity 250ms ease;
  transition: opacity 250ms ease; }
  .slick-slider .slick-dots:hover {
    opacity: 1; }
  .slick-slider .slick-dots li {
    line-height: 0;
    display: inline-block; }
    .slick-slider .slick-dots li button {
      font-size: 0;
      line-height: 0;
      z-index: 1;
      width: 21px;
      height: 21px;
      color: transparent;
      border: 4px solid #fff;
      border-radius: 21px;
      background: transparent;
      margin-right: 8px;
      margin-left: 8px;
      padding: 0; }
      .slick-slider .slick-dots li button:hover {
        background-color: #fff; }
    .slick-slider .slick-dots li.slick-active button {
      background-color: #fff; }

.slick-slider .slick-arrow {
  font-size: 0;
  line-height: 0;
  z-index: 1;
  color: transparent;
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.slick-slider .slick-prev {
  left: 0;
  width: 0;
  height: 0;
  border-width: 60px 60px 60px 0;
  border-style: solid;
  border-color: transparent #ffc400 transparent transparent; }

.slick-slider .slick-next {
  right: 0;
  width: 0;
  height: 0;
  border-width: 60px 0 60px 60px;
  border-style: solid;
  border-color: transparent transparent transparent #ffc400; }

input,
textarea {
  font-size: 1.25rem;
  line-height: 1.5;
  outline: none;
  margin-top: 32px;
  margin-bottom: 32px; }
  input[type='radio'],
  textarea[type='radio'] {
    position: relative;
    display: inline-block;
    width: 63px;
    height: 63px;
    vertical-align: middle;
    -webkit-border-radius: 63px;
    margin: 8px 12px 8px 8px;
    -webkit-border-color: #f4f4f4;
    -webkit-appearance: none; }
    input[type='radio']:before, input[type='radio']:after,
    textarea[type='radio']:before,
    textarea[type='radio']:after {
      display: block;
      content: '';
      border-radius: 63px; }
    input[type='radio']:before,
    textarea[type='radio']:before {
      border: 10px solid #f4f4f4;
      background-color: #f4f4f4;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    input[type='radio']:after,
    textarea[type='radio']:after {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      -webkit-transition: all 250ms ease;
      -moz-transition: all 250ms ease;
      transition: all 250ms ease; }
    input[type='radio']:focus:before,
    textarea[type='radio']:focus:before {
      border: 2px solid #c5c6c5; }
    input[type='radio']:checked:after,
    textarea[type='radio']:checked:after {
      background-color: #ffc400; }

input:not([type='radio']):not([type='checkbox']):not([type='submit']),
textarea {
  font-weight: 300;
  display: block;
  width: 100%;
  border: 0 solid #f4f4f4;
  border-radius: 0;
  background-color: #f4f4f4;
  padding: 21px 31px;
  margin-top: 16px;
  margin-bottom: 16px;
  -webkit-appearance: none; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit'])::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #95989a; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit'])::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #95989a; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):-moz-placeholder,
  textarea:-moz-placeholder {
    color: #95989a; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #95989a; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']).error,
  textarea.error {
    color: #ce251e;
    border-color: #ce251e; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
  textarea:focus {
    border-color: #f4f4f4;
    background-color: #f4f4f4; }

label {
  display: inline-block; }
  label + input {
    margin-top: 16px; }

textarea {
  min-height: 100px;
  resize: vertical; }

.banner {
  background-color: #000;
  background-position: center;
  background-size: cover; }
  .banner .banner__wrapper {
    color: #fff; }
    .banner .banner__wrapper h1 {
      color: #fff; }
  .banner .banner__button-container {
    margin-top: 56px; }
  .banner.banner--center .banner__wrapper {
    position: relative;
    text-align: center;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .banner.banner--center .banner__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 25.5894128995%; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .banner.banner--center .banner__wrapper > * {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
  .banner.banner--bottom .banner__wrapper {
    position: relative; }
    .banner.banner--bottom .banner__wrapper > * {
      position: absolute;
      bottom: 80px; }

.intro {
  background-size: cover;
  margin-top: 32px;
  margin-bottom: 32px; }
  .intro .intro__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto;
    padding: 120px 32px; }
    .intro .intro__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 74.4105871005%; }
      .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 12.7947064497%; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .intro .intro__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .intro .intro__copy {
    max-width: 795px; }
  .intro.text-position-left .intro__wrapper {
    text-align: left; }
    .intro.text-position-left .intro__wrapper h1:after {
      margin-left: 0 !important; }
  .intro.text-position-center .intro__wrapper {
    text-align: center;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .intro.text-position-center .intro__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .intro.text-position-center .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 74.4105871005%; }
      .intro.text-position-center .intro__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .intro.text-position-center .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 12.7947064497%; }
    .intro.text-position-center .intro__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .intro.text-position-center .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .intro.text-position-center .intro__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .intro.text-position-center .intro__copy {
    margin: 0 auto; }

.copy {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 80px 32px; }
  .copy .copy__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .copy .copy__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 57.3509785009%; }
      .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 8.5298042998%; }
    .copy .copy__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .copy .copy__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .copy.copy--alt-bg {
    background-color: #f4f4f4; }
  .copy.copy--center .copy__wrapper {
    text-align: center;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .copy.copy--center .copy__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 57.3509785009%; }
      .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 21.3245107496%; }
    .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .copy.copy--center .copy__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .copy.copy--center .copy__summary:before, .copy.copy--center .copy__summary:after {
    margin-left: 25%; }
  .copy .copy__summary {
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-top: 44px;
    margin-bottom: 44px; }
    .copy .copy__summary__inner {
      padding-top: 20px;
      padding-bottom: 20px; }
    .copy .copy__summary:before, .copy .copy__summary:after {
      display: block;
      width: 45%;
      content: '';
      border-top: 6px solid #ffc400;
      margin: 16px auto; }
  .copy .copy__gallery figure {
    margin-bottom: 17px; }
    .copy .copy__gallery figure img {
      margin-right: auto;
      margin-left: auto; }
  .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide)::after {
      clear: both;
      content: "";
      display: table; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 100%; }
      .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 0%; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):nth-child(1n+1) {
      margin-right: 0; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .copy .copy__gallery:not(.copy__gallery--two-col):not(.copy__gallery--two-col):not(.copy__gallery--wide) > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide)::after {
      clear: both;
      content: "";
      display: table; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 17.0596085997%; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+2) {
      margin-left: 0%; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .copy .copy__gallery.copy__gallery--two-col:not(.copy__gallery--wide) > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .copy .copy__gallery.copy__gallery--two-col > :nth-child(2n+1) {
    text-align: right; }
  .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide::after {
      clear: both;
      content: "";
      display: table; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 0%; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+2) {
      margin-left: 0%; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide figcaption {
      text-align: left; }
  .copy .copy__gallery.copy__gallery--three-col {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .copy .copy__gallery.copy__gallery--three-col::after {
      clear: both;
      content: "";
      display: table; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+1):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+2):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+3) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+3):last-child {
        margin-right: 0; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+3) {
      margin-right: 0; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n) {
      margin-right: 0; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(3n+1) {
      clear: both; }
    .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .copy .copy__gallery figure figcaption {
    text-align: center; }
  .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col):after {
    clear: both;
    content: '';
    display: table; }
  .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col) > *:not(style):not(script):nth-child(1n+1) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%; }
    .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col) > *:not(style):not(script):nth-child(1n+1):last-child {
      margin-right: 0; }
  .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col) > *:not(style):not(script):nth-child(1n+1) {
    margin-right: 0; }
  .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col) > *:not(style):not(script):nth-child(1n) {
    margin-right: 0; }
  .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col) > *:not(style):not(script):nth-child(1n+1) {
    clear: both; }
  .copy .copy__gallery.copy__gallery--wide:not(.copy__gallery--two-col):not(.copy__gallery--three-col) > *:not(style):not(script):last-child {
    margin-bottom: 0; }
  .copy .copy__gallery.copy__gallery--wide img {
    width: 100%;
    height: auto;
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .copy .copy__gallery.slider-container .slider figure {
    margin-bottom: 0; }

.videos-block .videos-block__column {
  clear: none !important; }

.gallery-block .copy__wrapper {
  padding-bottom: 45px; }

.cta {
  background-size: cover;
  margin-top: 32px;
  margin-bottom: 32px; }
  .cta .cta__wrapper {
    padding: 300px 32px 270px; }
    .cta .cta__wrapper h1 {
      font-size: 2.5rem;
      margin-bottom: .5em; }
  .cta.cta--form .cta__wrapper h1 {
    font-size: 1.5rem;
    line-height: 36px; }
    @media (min-width: 979px) {
      .cta.cta--form .cta__wrapper h1 {
        font-size: 1.875rem;
        line-height: 40px; } }
  .cta.cta--alt-bg {
    background-color: #ce251e; }
  .cta .cta__form {
    margin-top: 25px; }
    .cta .cta__form input[type='email'] {
      display: inline-block;
      width: 64%;
      max-width: 720px;
      margin-right: 30px; }
    .cta .cta__form .button {
      display: inline-block;
      color: #000;
      background: #ffc400; }

.image {
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px; }
  .image .image__copy {
    max-width: 662px; }
  .image .image__wrapper {
    min-height: 500px;
    padding: 80px 32px; }
    @media screen and (min-width: 65.5625em) {
      .image .image__wrapper .content__wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    .image .image__wrapper > div {
      position: relative; }
    .image .image__wrapper .copy {
      max-width: 662px; }
  .image.image--alt-bg {
    background-color: #ce251e; }
  .image.image--full.image--left .image__wrapper > figure:nth-child(1n), .image.image--full.image--right .image__wrapper > figure:nth-child(1n) {
    margin: 0; }
    .image.image--full.image--left .image__wrapper > figure:nth-child(1n) img, .image.image--full.image--right .image__wrapper > figure:nth-child(1n) img {
      max-height: 100%; }
  .image.image--full.image--left:not(.image--halves) .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 0%; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-left: 34.1192171993%; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--full.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--full.image--left.image--halves .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--full.image--left.image--halves .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 51.178825799%; }
    .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--full.image--left .image__wrapper > figure {
    margin-left: 0 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0; }
  .image.image--full.image--right:not(.image--halves) .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 0%; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-left: 0%; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--full.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--full.image--right.image--halves .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--full.image--right.image--halves .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 0%; }
    .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--full.image--right .image__wrapper > figure {
    margin-right: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; }
  .image.image--left:not(.image--halves) .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--left:not(.image--halves) .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--left.image--halves .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--left.image--halves .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--left.image--halves .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--right:not(.image--halves) .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--right:not(.image--halves) .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 68.2384343986%; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-left: -102.357651598%; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--right.image--halves .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--right.image--halves .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 46.6887231261%; }
      .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 53.3112768739%; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-left: -100.225200523%; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--right.image--halves .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .image.image--right .image__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .image.image--right .image__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 51.178825799%; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-left: -102.357651598%; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .image.image--right .image__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .image.image--right .image__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .image.image--right .image__wrapper figure img {
      margin: 0 auto; }

.callout {
  background-position: center;
  background-size: cover;
  margin-top: 0;
  margin-bottom: 32px; }
  .callout .callout__wrapper {
    font-weight: 300;
    color: #fff;
    background-position: center;
    background-size: cover;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .callout .callout__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 57.3509785009%; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 8.5298042998%; }
    .callout .callout__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .callout .callout__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .callout .callout__wrapper h1 {
      color: #fff; }
    .callout .callout__wrapper > div {
      padding: 80px 0 160px; }
  .callout.callout--full {
    background-color: #ed9721; }
  .callout:not(.callout--full) {
    padding-top: 40px;
    padding-bottom: 40px; }
    .callout:not(.callout--full) .callout__wrapper {
      background-color: #ed9721; }
  .callout.callout--alt-bg .callout__wrapper {
    color: #fff; }
    .callout.callout--alt-bg .callout__wrapper h1 {
      color: #fff; }
  .callout.callout--alt-bg.callout--full {
    background-color: #ce251e; }
  .callout.callout--alt-bg:not(.callout--full) .callout__wrapper {
    background-color: #ce251e; }
  .callout.callout--center {
    text-align: center; }
    .callout.callout--center .callout__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .callout.callout--center .callout__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 65.8807828007%; }
        .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 17.0596085997%; }
      .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .callout.callout--center .callout__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }

.feed {
  margin-top: 32px;
  margin-bottom: 32px; }
  .feed.feed--alt-bg {
    background-color: #f4f4f4; }
  .feed .feed__wrapper {
    padding: 80px 32px; }
    .feed .feed__wrapper .feed__intro {
      margin-bottom: 80px;
      text-align: center;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .feed .feed__wrapper .feed__intro::after {
        clear: both;
        content: "";
        display: table; }
      .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 1em;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 65.8807828007%; }
        .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 17.0596085997%; }
      .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__intro > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .feed .feed__wrapper .feed__items[class*='-col'] .feed__item {
      vertical-align: top; }
      .feed .feed__wrapper .feed__items[class*='-col'] .feed__item figure {
        display: block;
        margin-bottom: 2em; }
      .feed .feed__wrapper .feed__items[class*='-col'] .feed__item:nth-child(1) figure, .feed .feed__wrapper .feed__items[class*='-col'] .feed__item:nth-child(2) figure, .feed .feed__wrapper .feed__items[class*='-col'] .feed__item:nth-child(3) figure {
        margin-top: 0; }
    .feed .feed__wrapper .feed__items[class*='-col'].feed__items--four-col .feed__item:nth-child(4) figure {
      margin-top: 0; }
    .feed .feed__wrapper .feed__items[class*='-col'].feed__items--five-col .feed__item:nth-child(5) figure {
      margin-top: 0; }
    .feed .feed__wrapper .feed__items .feed__item div > h1 {
      margin-bottom: 0;
      color: #000; }
      .feed .feed__wrapper .feed__items .feed__item div > h1 + em {
        display: inline-block;
        margin-bottom: 1.75em; }
    .feed .feed__wrapper .feed__items .feed__item figure img {
      margin-right: auto;
      margin-left: auto; }
    .feed .feed__wrapper .feed__items.feed__items--two-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .feed .feed__wrapper .feed__items.feed__items--two-col::after {
        clear: both;
        content: "";
        display: table; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.7245755582%;
        width: 21.4565683314%; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+1) {
        margin-left: 26.1811438895%; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.7245755582%;
        width: 21.4565683314%; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+2) {
        margin-left: 0%; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .feed .feed__wrapper .feed__items.feed__items--three-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .feed .feed__wrapper .feed__items.feed__items--three-col::after {
        clear: both;
        content: "";
        display: table; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 3.5786876674%;
        width: 18.0002055967%; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+1) {
        margin-left: 19.4210039376%; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+2) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 3.5786876674%;
        width: 18.0002055967%; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+2):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+2) {
        margin-left: 0%; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+3) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 3.5786876674%;
        width: 18.0002055967%; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+3):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+3) {
        margin-left: 0%; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(3n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .feed .feed__wrapper .feed__items.feed__items--four-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .feed .feed__wrapper .feed__items.feed__items--four-col::after {
        clear: both;
        content: "";
        display: table; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.4341602212%;
        width: 21.6743798341%; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+2) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.4341602212%;
        width: 21.6743798341%; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+2):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+3) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.4341602212%;
        width: 21.6743798341%; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+3):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+4) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.4341602212%;
        width: 21.6743798341%; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+4):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+4) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(4n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .feed .feed__wrapper .feed__items.feed__items--five-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .feed .feed__wrapper .feed__items.feed__items--five-col::after {
        clear: both;
        content: "";
        display: table; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.3554179978%;
        width: 16.5156656017%; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+2) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.3554179978%;
        width: 16.5156656017%; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+2):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+3) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.3554179978%;
        width: 16.5156656017%; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+3):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+4) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.3554179978%;
        width: 16.5156656017%; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+4):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+5) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.3554179978%;
        width: 16.5156656017%; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+5):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+5) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(5n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .feed .feed__wrapper .feed__items:not([class*='-col']) {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .feed .feed__wrapper .feed__items:not([class*='-col'])::after {
        clear: both;
        content: "";
        display: table; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 82.9403914003%; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 8.5298042998%; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image:after {
        clear: both;
        content: '';
        display: table; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n+1) {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n+2) {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 74.4105871005%; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n+2) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image figure:after {
        width: 100%; }
  .feed.feed--center .feed__wrapper {
    text-align: center; }
    .feed.feed--center .feed__wrapper .feed__items .feed__item:not(.feed__item--image):after {
      float: none;
      margin-right: auto !important;
      margin-left: auto !important;
      margin-right: auto;
      margin-left: auto; }

.quote {
  margin-top: 44px;
  margin-bottom: 44px; }
  .quote .quote__wrapper {
    background-position: center;
    background-size: cover;
    padding: 120px 32px;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .quote .quote__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 17.0596085997%; }
    .quote .quote__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .quote .quote__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .quote .quote__wrapper footer {
      margin-top: 4em; }
  .quote.quote--center .quote__wrapper {
    text-align: center; }

.map {
  margin-top: 32px;
  margin-bottom: 32px; }
  .map .map__wrapper {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    min-height: 741px;
    background-color: #ffc400; }
    .map .map__wrapper h1 {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5;
      margin: 0 0 1em 0;
      color: #000; }
    .map .map__wrapper .map__left {
      font-weight: 300;
      color: #000;
      padding: 80px 10% 80px 5%; }
      .map .map__wrapper .map__left a {
        font-weight: 300;
        text-decoration: none;
        color: #000; }
        .map .map__wrapper .map__left a:not(.button) {
          border-bottom: 2px solid #000; }
          .map .map__wrapper .map__left a:not(.button):hover {
            color: #000;
            border-bottom: 2px solid #ce251e; }
    .map .map__wrapper .map__right {
      min-height: 741px; }

.contact-form {
  padding-top: 0;
  margin-top: 32px;
  margin-bottom: 32px; }
  .contact-form .contact-form__intro {
    margin-bottom: 50px !important; }
  .contact-form .copy__wrapper {
    padding: 40px 16px;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .contact-form .copy__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 17.0596085997%; }
    .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .contact-form .copy__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .contact-form .copy__wrapper form label {
      margin-bottom: 1em; }
      .contact-form .copy__wrapper form label:not(:first-of-type) {
        margin-top: 2em; }
      .contact-form .copy__wrapper form label + label:not(:first-of-type) {
        margin-top: 0; }
      .contact-form .copy__wrapper form label:last-child {
        margin-bottom: 0; }
    .contact-form .copy__wrapper .form__inputs.form__inputs--two-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col::after {
        clear: both;
        content: "";
        display: table; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+2) {
        margin-right: 0; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
  .contact-form.form--alt-bg {
    background-color: #f4f4f4; }
    .contact-form.form--alt-bg form label {
      color: #000; }
    .contact-form.form--alt-bg h1,
    .contact-form.form--alt-bg h2 {
      color: #000; }

.form-block {
  margin: 80px 0;
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  .form-block::after {
    clear: both;
    content: "";
    display: table; }
  .form-block > *:not(style):not(script):nth-child(1n+1) {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%; }
    .form-block > *:not(style):not(script):nth-child(1n+1):last-child {
      margin-right: 0; }
  .form-block > *:not(style):not(script):nth-child(1n+1) {
    margin-right: 0; }
  .form-block > *:not(style):not(script):nth-child(1n) {
    margin-right: 0; }
  .form-block > *:not(style):not(script):nth-child(1n+1) {
    clear: both; }
  .form-block > *:not(style):not(script):last-child {
    margin-bottom: 0; }
  .form-block.form-block--bg-color-lightGray .form-block__wrapper {
    background-color: #f4f4f4; }
  .form-block .form-block__wrapper {
    background-size: cover; }
  .form-block .form-block__copy {
    position: relative; }
    .form-block .form-block__copy .button {
      position: absolute;
      bottom: 42px;
      left: 0; }
  .form-block form input:not([type='radio']):not([type='checkbox']):not([type='submit']),
  .form-block form textarea {
    font-size: 16px;
    padding: 14px 20px !important;
    background: #fff !important; }
  .form-block form select,
  .form-block form .dk-select {
    margin-top: 16px; }
  .form-block form input:not([type='radio']):not([type='checkbox']):not([type='submit']),
  .form-block form textarea,
  .form-block form select {
    font-weight: 300;
    border: 1px solid #95989a !important; }
  .form-block form .dk-selected {
    border: 1px solid #95989a !important; }
  .form-block .button {
    background: #ffc400 !important; }
    .form-block .button:hover {
      background: #ce251e !important; }
  @media (max-width: 978px) {
    .form-block .form-block__wrapper {
      padding: 40px 0; }
    .form-block .form-block__inner {
      padding: 0 2em;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .form-block .form-block__inner::after {
        clear: both;
        content: "";
        display: table; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 100%; }
        .form-block .form-block__inner > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(1n+1) {
        margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .form-block .form-block__inner > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .form-block .form-block__copy {
      padding-bottom: 30px; }
    .form-block.js .form-block__copy .button {
      display: none; } }
  @media (min-width: 979px) {
    .form-block .form-block__copy {
      padding-top: 40px;
      padding-bottom: 100px; }
    .form-block.js .form-block__form .button {
      display: none; } }
  @media (min-width: 979px) and (max-width: 1200px) {
    .form-block .form-block__wrapper {
      padding: 80px 0; }
    .form-block .form-block__inner {
      padding: 0 2em;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .form-block .form-block__inner::after {
        clear: both;
        content: "";
        display: table; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1) {
        margin-left: 68.2384343986%; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 61.6158806508%; }
        .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+2) {
        margin-left: -102.357651598%; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .form-block .form-block__inner > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 1201px) {
    .form-block .form-block__wrapper {
      padding: 127px 0 260px 0; }
    .form-block .form-block__inner {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .form-block .form-block__inner::after {
        clear: both;
        content: "";
        display: table; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 18.9668591516%; }
        .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1) {
        margin-left: 63.9735322487%; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 40.2913699012%; }
        .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+2) {
        margin-left: -68.2384343986%; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .form-block .form-block__inner > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .form-block .form-block__inner > *:not(style):not(script):last-child {
        margin-bottom: 0; } }

.freeform-pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px;
  padding: 0;
  list-style: none; }

.freeform-pages li {
  margin: 0 10px 0 0; }

.freeform-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -15px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .freeform-row .freeform-column {
    box-sizing: border-box;
    margin: 0 15px;
    padding: 10px 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0; }

.freeform-row .freeform-column label {
  display: block; }

.freeform-row .freeform-column .freeform-label {
  font-weight: 300; }

.freeform-row .freeform-column .freeform-label.freeform-required:after {
  margin-left: 5px;
  content: '*'; }

.freeform-row .freeform-column .freeform-input-only-label {
  font-weight: normal; }

.freeform-row .freeform-column .freeform-input-only-label > .freeform-input {
  display: inline-block;
  width: auto;
  margin-right: 5px; }

.freeform-row .freeform-column .freeform-errors {
  margin: 5px 0 0;
  padding: 0;
  list-style: none; }

.freeform-row .freeform-column .freeform-errors > li {
  color: #ce251e; }

.freeform-row .freeform-column .freeform-instructions {
  font-size: 13px;
  margin: 0 0 5px;
  color: #c5c6c5; }

.freeform-row .freeform-column.freeform-column-content-align-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.freeform-row .freeform-column.freeform-column-content-align-left > button:not(:first-of-type) {
  margin-left: 5px; }

.freeform-row .freeform-column.freeform-column-content-align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.freeform-row .freeform-column.freeform-column-content-align-center > button:not(:first-of-type) {
  margin-left: 5px; }

.freeform-row .freeform-column.freeform-column-content-align-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.freeform-row .freeform-column.freeform-column-content-align-right > button:not(:first-of-type) {
  margin-left: 5px; }

.freeform-row .freeform-column.freeform-column-content-align-spread {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.freeform-row .freeform-column.freeform-column-content-align-spread > button:not(:first-of-type) {
  margin-left: 5px; }

.freeform-form-has-errors {
  color: #ce251e; }

.signup {
  background-position: center;
  background-size: cover;
  margin-top: 32px;
  margin-bottom: 32px; }
  .signup .signup__wrapper {
    background-position: center;
    background-size: cover;
    padding: 160px 32px;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .signup .signup__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 57.3509785009%; }
      .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+1) {
      margin-left: 8.5298042998%; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 23.2317613015%; }
      .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-left: 0%; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .signup .signup__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .signup .signup__wrapper .form__address {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .signup .signup__wrapper .form__address::after {
        clear: both;
        content: "";
        display: table; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 65.8807828007%; }
        .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+1):last-child {
          margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+2):last-child {
          margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+2) {
        margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+3) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+3):last-child {
          margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+4) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 14.7019570017%; }
        .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+4):last-child {
          margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+5) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+5):last-child {
          margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+5) {
        margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n) {
        margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(5n+1) {
        clear: both; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .signup .signup__wrapper.signup__wrapper--alt-bg {
      background-color: #ce251e; }
  .signup.signup--alt-bg {
    background-color: #ce251e; }

.council-members .council-members__list {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 32px; }
  .council-members .council-members__list::after {
    clear: both;
    content: "";
    display: table; }
  .council-members .council-members__list .council-members__wrap.last {
    padding-top: 20px; }
  .council-members .council-members__list .council-members__wrap h1 {
    font-weight: 400;
    padding-bottom: 40px;
    text-transform: uppercase; }

.council-members .council-members__items::after {
  clear: both;
  content: "";
  display: table; }

.council-members a.council-members__item {
  font-weight: 500;
  display: block;
  border-bottom: 0 !important; }
  .council-members a.council-members__item small {
    font-weight: 600;
    display: block;
    margin-bottom: 12px; }
  .council-members a.council-members__item p {
    font-size: 1.25rem;
    line-height: 1.5;
    color: #000;
    -webkit-transition: color 250ms;
    -moz-transition: color 250ms;
    transition: color 250ms; }
  .council-members a.council-members__item hr {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 12px;
    border: 0;
    background-color: #ffc400; }
    .council-members a.council-members__item hr:before {
      position: absolute;
      top: 0;
      left: -100%;
      display: block;
      width: 100%;
      height: 12px;
      content: '';
      transition: left 0.8s cubic-bezier(0.5, 0.25, 0.01, 1);
      animation: text-link-hover 0.8s cubic-bezier(0.5, 0.25, 0.01, 1) 0s 1;
      background: #ce251e;
      background: -moz-linear-gradient(left, #ce251e 0%, #ce261e 16%, #e05120 25%, #ec6821 42%, #f37121 49%, #f37121 62%, #ffc400 100%);
      background: -webkit-linear-gradient(left, #ce251e 0%, #ce261e 16%, #e05120 25%, #ec6821 42%, #f37121 49%, #f37121 62%, #ffc400 100%);
      background: linear-gradient(to right, #ce251e 0%, #ce261e 16%, #e05120 25%, #ec6821 42%, #f37121 49%, #f37121 62%, #ffc400 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ce251e', endColorstr='#ffc400', GradientType=1); }
  .council-members a.council-members__item:hover {
    text-decoration: none;
    border-bottom: 0 !important; }
    .council-members a.council-members__item:hover p {
      color: #ce251e; }
    .council-members a.council-members__item:hover hr:before {
      left: 0; }
  .council-members a.council-members__item:visited {
    border-bottom: 0 !important; }

@media (max-width: 567px) {
  .council-members .council-members__wrap {
    padding-right: 18px;
    padding-left: 18px; }
  .council-members a.council-members__item {
    margin-bottom: 50px; } }

@media (min-width: 568px) {
  .council-members .council-members__wrap {
    padding: 80px 32px 0;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .council-members .council-members__wrap::after {
      clear: both;
      content: "";
      display: table; }
    .council-members .council-members__wrap > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 91.4701957002%; }
      .council-members .council-members__wrap > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .council-members .council-members__wrap > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 4.2649021499%; }
    .council-members .council-members__wrap > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .council-members .council-members__wrap > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .council-members .council-members__wrap > *:not(style):not(script):last-child {
      margin-bottom: 0; } }

@media (min-width: 568px) and (max-width: 64.5625em) {
  .council-members a.council-members__item {
    display: inline-block;
    float: left;
    width: 45%;
    margin-right: 10%;
    margin-bottom: 60px; }
    .council-members a.council-members__item:nth-child(2n) {
      margin-right: 0; } }

@media (min-width: 65.5625em) and (max-width: 1399px) {
  .council-members a.council-members__item {
    display: inline-block;
    float: left;
    width: 26.6%;
    margin-right: 10.1%;
    margin-bottom: 60px; }
    .council-members a.council-members__item:nth-child(3n) {
      margin-right: 0; } }

@media (min-width: 1400px) {
  .council-members a.council-members__item {
    display: inline-block;
    float: left;
    width: 17.3%;
    margin-right: 10.266667%;
    margin-bottom: 80px; }
    .council-members a.council-members__item:nth-child(4n) {
      margin-right: 0; } }

.council-members__map {
  height: 300px; }
  @media (min-width: 480px) and (max-width: 767px) {
    .council-members__map {
      height: 500px; } }
  @media (min-width: 768px) {
    .council-members__map {
      height: 750px; } }

.council-members__infobox {
  font-size: 0.75rem;
  line-height: 1.58333;
  position: relative;
  z-index: 10;
  display: block;
  overflow: hidden;
  width: 254px;
  height: auto;
  padding: 17px 21px 17px 21px;
  cursor: pointer;
  animation: fade-in-infobox 300ms;
  text-transform: uppercase;
  color: #000;
  border-radius: 10px;
  background: #fff;
  letter-spacing: 0.2em; }
  .council-members__infobox .overlay {
    position: absolute;
    z-index: 25;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-bottom: 0 !important; }
    .council-members__infobox .overlay:hover, .council-members__infobox .overlay:active {
      border-bottom: 0 !important; }
  .council-members__infobox p.health-system {
    display: block;
    margin-bottom: 8px;
    opacity: .5; }
  .council-members__infobox p.health-system,
  .council-members__infobox p.name {
    font-weight: 600;
    position: relative;
    z-index: 20; }
  .council-members__infobox p.name a {
    text-decoration: none;
    color: #000;
    border-bottom: 0 !important; }
    .council-members__infobox p.name a:hover, .council-members__infobox p.name a:active {
      text-decoration: none;
      border-bottom: 0 !important; }
  .council-members__infobox .plus {
    position: absolute;
    z-index: 20;
    right: -67px;
    bottom: -72px;
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%; }
    .council-members__infobox .plus span {
      position: absolute;
      z-index: 21;
      top: 20px;
      left: 25px;
      display: block;
      width: 16px;
      height: 16px;
      transition: transform .25s ease-in-out; }
      .council-members__infobox .plus span:before, .council-members__infobox .plus span:after {
        position: absolute;
        display: block;
        content: '';
        background: #fff; }
      .council-members__infobox .plus span:before {
        top: 7px;
        left: 0;
        width: 16px;
        height: 2px; }
      .council-members__infobox .plus span:after {
        top: 0;
        left: 7px;
        width: 2px;
        height: 16px; }
  .council-members__infobox:hover .plus span {
    transform: rotate(90deg); }

@-webkit-keyframes fade-in-infobox {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in-infobox {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes text-link-hover {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  50.01% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes text-link-hover {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  50.01% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.news-listing {
  padding: 64px 16px 89px; }
  .news-listing.news-listing--alt-bg {
    background-color: #f4f4f4; }
  .news-listing h2.news-listing__heading {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 46px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em; }
  .news-listing .news-listing__items {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .news-listing .news-listing__items::after {
      clear: both;
      content: "";
      display: table; }
  .news-listing .news-listing__item {
    padding-bottom: 20px; }
    .news-listing .news-listing__item .meta {
      font-weight: 600;
      display: block;
      margin-bottom: 20px; }
    .news-listing .news-listing__item .title,
    .news-listing .news-listing__item .teaser {
      font-weight: 400;
      position: relative;
      z-index: 11;
      display: block; }
    .news-listing .news-listing__item .title {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 34px; }
    .news-listing .news-listing__item a.title {
      color: #000;
      border-bottom: 0 !important;
      -webkit-transition: color 250ms;
      -moz-transition: color 250ms;
      transition: color 250ms; }
      .news-listing .news-listing__item a.title:hover, .news-listing .news-listing__item a.title:active {
        color: #ce251e;
        border-bottom: 0 !important; }
    .news-listing .news-listing__item .teaser {
      font-size: 1rem;
      line-height: 30px;
      margin-top: 20px; }
  .news-listing:not(.boxes) .news-listing__item {
    padding-bottom: 100px; }
  .news-listing a.news-listing__box {
    position: relative;
    display: block;
    overflow: hidden !important;
    padding: 21px 20px 49px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    color: #000 !important;
    border-bottom: 0 !important;
    border-radius: 10px;
    background: #fff;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; }
    .news-listing a.news-listing__box .arrow {
      position: absolute;
      z-index: 11;
      right: -83px;
      bottom: -100px;
      display: block;
      overflow: hidden;
      width: 162px;
      height: 162px;
      border-radius: 50%; }
      .news-listing a.news-listing__box .arrow img {
        position: absolute;
        z-index: 13;
        top: 30px;
        left: 36px;
        width: 23px;
        height: 15px; }
      .news-listing a.news-listing__box .arrow:before, .news-listing a.news-listing__box .arrow:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        -webkit-transition: opacity 100ms;
        -moz-transition: opacity 100ms;
        transition: opacity 100ms; }
      .news-listing a.news-listing__box .arrow:before {
        z-index: 13;
        opacity: 0; }
      .news-listing a.news-listing__box .arrow:after {
        z-index: 12;
        opacity: 1; }
    .news-listing a.news-listing__box .news-listing__item-bg {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 10px;
      -webkit-transition: opacity 250ms;
      -moz-transition: opacity 250ms;
      transition: opacity 250ms; }
      .news-listing a.news-listing__box .news-listing__item-bg:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        opacity: .3;
        background: #000; }
    .news-listing a.news-listing__box:hover, .news-listing a.news-listing__box:visited {
      border-bottom: 0 !important;
      border-radius: 10px !important; }
    .news-listing a.news-listing__box:hover .arrow:before {
      opacity: 1; }
    .news-listing a.news-listing__box:hover .arrow:after {
      opacity: 0; }
    .news-listing a.news-listing__box.has-bg:hover {
      color: #fff !important; }
      .news-listing a.news-listing__box.has-bg:hover .news-listing__item-bg {
        opacity: 1; }
    .news-listing a.news-listing__box:not(.has-bg):hover {
      color: #000 !important; }
  .news-listing .arrow-link {
    display: block;
    margin-top: 30px;
    color: #ce251e;
    border-bottom: 0 !important; }
    .news-listing .arrow-link:hover, .news-listing .arrow-link:active, .news-listing .arrow-link:visited {
      color: #ce251e;
      border-bottom: 0 !important; }
    .news-listing .arrow-link .label {
      font-size: 1rem;
      font-weight: 600;
      line-height: 40px;
      display: inline-block;
      margin-right: 6px;
      vertical-align: top;
      text-transform: uppercase;
      letter-spacing: 0.2em; }
    .news-listing .arrow-link .arrow {
      position: relative;
      z-index: 11;
      display: inline-block;
      width: 40px;
      height: 40px;
      vertical-align: top; }
      .news-listing .arrow-link .arrow img.red-arrow {
        position: absolute;
        z-index: 13;
        top: 50%;
        left: 0;
        display: block;
        width: 29px;
        height: 19px;
        margin: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); }
      .news-listing .arrow-link .arrow .circle-container {
        position: absolute;
        z-index: 12;
        top: 0;
        left: 0;
        display: block;
        width: 40px;
        height: 40px; }
        .news-listing .arrow-link .arrow .circle-container span {
          display: block; }
        .news-listing .arrow-link .arrow .circle-container .bottom,
        .news-listing .arrow-link .arrow .circle-container .top {
          position: absolute;
          left: 0;
          width: 40px;
          height: 20px;
          -webkit-backface-visibility: hidden; }
          .news-listing .arrow-link .arrow .circle-container .bottom img,
          .news-listing .arrow-link .arrow .circle-container .top img {
            width: 40px;
            height: 20px;
            -webkit-transition: transform .12s linear;
            transition: transform .12s linear;
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
        .news-listing .arrow-link .arrow .circle-container .bottom {
          z-index: 14;
          bottom: 0;
          overflow: hidden;
          background: #fff; }
          .news-listing .arrow-link .arrow .circle-container .bottom img {
            -webkit-transition-delay: .12s;
            transition-delay: .12s;
            -webkit-transform-origin: center top;
            -ms-transform-origin: center top;
            transform-origin: center top; }
        .news-listing .arrow-link .arrow .circle-container .top {
          z-index: 13;
          top: 0; }
          .news-listing .arrow-link .arrow .circle-container .top img {
            -webkit-transition-delay: 0s;
            transition-delay: 0s;
            -webkit-transform-origin: center bottom;
            -ms-transform-origin: center bottom;
            transform-origin: center bottom; }
    @media (max-width: 978px) {
      .news-listing .arrow-link .arrow .circle-container {
        border: 2px solid #ffc400;
        border-radius: 50px; }
        .news-listing .arrow-link .arrow .circle-container .bottom,
        .news-listing .arrow-link .arrow .circle-container .top {
          display: none; } }
    @media (min-width: 979px) {
      .news-listing .arrow-link:hover .arrow .circle-container .bottom img {
        -webkit-transition-delay: 0s;
        transition-delay: 0s; }
      .news-listing .arrow-link:hover .arrow .circle-container .top img {
        -webkit-transition-delay: .25s;
        transition-delay: .25s; }
      .news-listing .arrow-link:hover .arrow .circle-container .bottom img,
      .news-listing .arrow-link:hover .arrow .circle-container .top img {
        -webkit-transition-duration: .25s;
        transition-duration: .25s;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg); } }
    .news-listing .arrow-link:hover, .news-listing .arrow-link:active {
      border-bottom: 0 !important; }
  @media (min-width: 480px) {
    .news-listing {
      padding: 90px 16px 150px; }
      .news-listing a.news-listing__box {
        padding: 30px 25px 35px; }
      .news-listing.boxes .news-listing__item {
        padding-bottom: 40px; } }
  @media (min-width: 640px) {
    .news-listing {
      padding: 120px 16px 200px; }
      .news-listing h2.news-listing__heading {
        font-size: 1.875rem;
        line-height: 40px;
        margin-bottom: 85px; }
      .news-listing a.news-listing__box {
        padding: 45px 35px 50px; }
        .news-listing a.news-listing__box .arrow {
          right: -142px;
          bottom: -161px;
          width: 247px;
          height: 247px; }
          .news-listing a.news-listing__box .arrow img {
            top: 43px;
            left: 51px;
            width: 28px;
            height: 18px; }
      .news-listing.boxes .news-listing__item {
        padding-bottom: 60px; } }
  @media (min-width: 768px) {
    .news-listing {
      padding: 141px 32px 263px; }
      .news-listing a.news-listing__box {
        padding: 57px 46px; }
      .news-listing .news-listing__item {
        padding-bottom: 84px; } }
  @media (max-width: 978px) {
    .news-listing .news-listing__item {
      max-width: 536px;
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 979px) and (max-width: 1399px) {
    .news-listing.news-listing--3-cols .news-listing__items {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-listing.news-listing--3-cols .news-listing__items::after {
        clear: both;
        content: "";
        display: table; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n+2) {
        margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 979px) and (max-width: 1099px) {
    .news-listing.news-listing--4-cols .news-listing__items {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-listing.news-listing--4-cols .news-listing__items::after {
        clear: both;
        content: "";
        display: table; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n+2) {
        margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 1100px) and (max-width: 1399px) {
    .news-listing.news-listing--4-cols .news-listing__items {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-listing.news-listing--4-cols .news-listing__items::after {
        clear: both;
        content: "";
        display: table; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+1):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+2):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+3) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+3):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+3) {
        margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n) {
        margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(3n+1) {
        clear: both; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 1400px) {
    .news-listing.news-listing--3-cols .news-listing__items {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-listing.news-listing--3-cols .news-listing__items::after {
        clear: both;
        content: "";
        display: table; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+1):last-child {
          margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+2):last-child {
          margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+3) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%; }
        .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+3):last-child {
          margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+3) {
        margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n) {
        margin-right: 0; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):nth-child(3n+1) {
        clear: both; }
      .news-listing.news-listing--3-cols .news-listing__items > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .news-listing.news-listing--4-cols .news-listing__items {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-listing.news-listing--4-cols .news-listing__items::after {
        clear: both;
        content: "";
        display: table; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+1):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+2):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+3) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+3):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+4) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%; }
        .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+4):last-child {
          margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+4) {
        margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n) {
        margin-right: 0; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):nth-child(4n+1) {
        clear: both; }
      .news-listing.news-listing--4-cols .news-listing__items > *:not(style):not(script):last-child {
        margin-bottom: 0; } }

#ROI {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  #ROI::after {
    clear: both;
    content: "";
    display: table; }
  #ROI > *:not(style):not(script):nth-child(2n+1) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 6.2177604309%;
    width: 46.8911197845%; }
    #ROI > *:not(style):not(script):nth-child(2n+1):last-child {
      margin-right: 0; }
  #ROI > *:not(style):not(script):nth-child(2n+2) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 6.2177604309%;
    width: 46.8911197845%; }
    #ROI > *:not(style):not(script):nth-child(2n+2):last-child {
      margin-right: 0; }
  #ROI > *:not(style):not(script):nth-child(2n+2) {
    margin-right: 0; }
  #ROI > *:not(style):not(script):nth-child(2n) {
    margin-right: 0; }
  #ROI > *:not(style):not(script):nth-child(2n+1) {
    clear: both; }
  #ROI > *:not(style):not(script):last-child {
    margin-bottom: 0; }
  #ROI .output {
    padding-left: 24px;
    opacity: 0;
    border-left: 2px solid #bcbec0; }
    #ROI .output .label {
      font-size: 1rem;
      margin-bottom: .5em;
      text-transform: uppercase; }
    #ROI .output .value {
      font-size: 2.5rem;
      margin-bottom: .5em;
      color: #ce251e; }
  #ROI label + div {
    margin-top: -8px; }
  #ROI .dollar-field {
    position: relative; }
    #ROI .dollar-field input[type='number'] {
      text-align: right;
      padding-right: 46.5px;
      padding-left: 46.5px; }
    #ROI .dollar-field:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      padding: 17px 0 17px 31px;
      content: '$'; }
  #ROI .percent-field {
    position: relative; }
    #ROI .percent-field input[type='number'] {
      text-align: right;
      padding-right: 46.5px;
      padding-left: 46.5px; }
    #ROI .percent-field:before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      padding: 17px 31px 17px 0;
      content: '%'; }

#mc_embed_signup {
  margin-top: 32px;
  margin-bottom: 32px;
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  #mc_embed_signup::after {
    clear: both;
    content: "";
    display: table; }
  #mc_embed_signup > *:not(style):not(script):nth-child(1n+1) {
    margin-bottom: 0;
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 65.8807828007%; }
    #mc_embed_signup > *:not(style):not(script):nth-child(1n+1):last-child {
      margin-right: 0; }
  #mc_embed_signup > *:not(style):not(script):nth-child(1n+1) {
    margin-left: 17.0596085997%; }
  #mc_embed_signup > *:not(style):not(script):nth-child(1n) {
    margin-right: 0; }
  #mc_embed_signup > *:not(style):not(script):nth-child(1n+1) {
    clear: both; }
  #mc_embed_signup > *:not(style):not(script):last-child {
    margin-bottom: 0; }
  #mc_embed_signup form {
    padding: 80px 32px; }
    #mc_embed_signup form .form__inputs.form__inputs--two-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col::after {
        clear: both;
        content: "";
        display: table; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.7461357194%;
        width: 48.6269321403%; }
        #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.7461357194%;
        width: 48.6269321403%; }
        #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+2) {
        margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    #mc_embed_signup form input {
      margin-bottom: 0; }
  #mc_embed_signup .indicates-required {
    color: #ce251e; }
  #mc_embed_signup div.mce_inline_error {
    background-color: #ce251e !important; }
  #mc_embed_signup input.mce_inline_error {
    border-color: #ce251e !important; }
  #mc_embed_signup label,
  #mc_embed_signup div#mce-responses {
    margin-top: 1em; }
  #mc_embed_signup .button {
    float: right;
    margin-top: 1em; }

.dk-select,
.dk-select *,
.dk-select *:before,
.dk-select *:after,
.dk-select-multi,
.dk-select-multi *,
.dk-select-multi *:before,
.dk-select-multi *:after {
  box-sizing: border-box; }

.dk-select,
.dk-select-multi {
  line-height: 1.5em;
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  text-align: left;
  vertical-align: middle; }

.dk-selected {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 16px 24px;
  white-space: nowrap;
  border: 2px solid #f4f4f4;
  border-radius: 0;
  background-color: white;
  text-overflow: ellipsis; }
  .dk-selected:before, .dk-selected:after {
    position: absolute;
    right: 0;
    display: block;
    content: ''; }
  .dk-selected:before {
    top: 50%;
    margin: -.7em 1em 0 0;
    color: #82bc49;
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    content: "";
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .dk-selected:after {
    top: 0;
    height: 100%;
    margin: 0 1.5em 0 0; }

.dk-selected-disabled {
  color: #f4f4f4; }

.dk-select .dk-select-options {
  position: absolute;
  right: 0;
  left: 0;
  display: none; }

.dk-select-open-up .dk-select-options {
  bottom: 100%;
  margin-bottom: -2px;
  border-radius: 0 0 0 0; }

.dk-select-open-down .dk-select-options {
  top: 100%;
  margin-top: -2px;
  border-radius: 0 0 0 0; }

.dk-select-multi .dk-select-options {
  max-height: 10em; }

.dk-select-options {
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;
  min-width: 100%;
  max-height: 15em;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ce251e;
  border-radius: 0;
  background-color: white; }

.dk-option-selected {
  color: #95989a;
  background-color: #f4f4f4; }

.dk-select-options-highlight .dk-option-selected {
  color: inherit;
  background-color: transparent; }

.dk-option {
  margin-left: 0;
  padding: .625rem 1.25rem; }
  .dk-option:before {
    content: ''; }

.dk-select-options .dk-option-highlight {
  font-weight: 300;
  color: #fff;
  background-color: #f4f4f4; }

.dk-select-options .dk-option-disabled {
  display: none;
  color: #f4f4f4;
  background-color: transparent; }

.dk-optgroup {
  margin-top: .25em;
  padding: .25em 0;
  border: solid #ce251e;
  border-width: 1px 0; }
  .dk-optgroup + .dk-option {
    margin-top: .25em; }
  .dk-optgroup + .dk-optgroup {
    margin-top: 0;
    border-top-width: 0; }
  .dk-optgroup:nth-child(2) {
    margin-top: 0;
    padding-top: 0;
    border-top: none; }
  .dk-optgroup:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom-width: 0; }

.dk-optgroup-label {
  font-weight: 300;
  width: 100%;
  padding: 0 .5em .25em; }

.dk-optgroup-options {
  padding-left: 0;
  list-style: none; }
  .dk-optgroup-options li {
    padding-left: 1.2em; }

.dk-select-open-up .dk-selected {
  border-color: #f4f4f4;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.dk-select-open-down .dk-selected {
  border-color: #f4f4f4;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.dk-select-open-up .dk-selected:before,
.dk-select-open-down .dk-selected:before {
  color: #82bc49;
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  content: "";
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dk-select-open-up .dk-selected:after,
.dk-select-open-down .dk-selected:after {
  border-left-color: #f4f4f4; }

.dk-select-open-up .dk-select-options,
.dk-select-open-down .dk-select-options,
.dk-select-multi:focus .dk-select-options {
  display: block;
  border-color: #f4f4f4; }

.dk-select-multi:hover,
.dk-select-multi:focus {
  outline: none; }

.dk-selected:hover,
.dk-selected:focus {
  border-color: #f4f4f4;
  outline: none; }
  .dk-selected:hover:before,
  .dk-selected:focus:before {
    border-top-color: #f4f4f4; }
  .dk-selected:hover:after,
  .dk-selected:focus:after {
    border-left-color: #f4f4f4; }

.dk-select-disabled {
  cursor: not-allowed;
  opacity: .6;
  color: #f4f4f4; }
  .dk-select-disabled .dk-selected:hover, .dk-select-disabled .dk-selected:focus {
    border-color: inherit; }
    .dk-select-disabled .dk-selected:hover:before, .dk-select-disabled .dk-selected:focus:before {
      border-top-color: inherit; }
    .dk-select-disabled .dk-selected:hover:after, .dk-select-disabled .dk-selected:focus:after {
      border-left-color: inherit; }

select[data-dkcacheid] {
  display: none; }

.split-view.split-initialized {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  margin-left: auto; }
  .split-view.split-initialized:hover .handle {
    opacity: 1; }
  .split-view.split-initialized .handle {
    z-index: 100;
    width: 32px;
    margin-left: -16px;
    cursor: col-resize;
    opacity: .5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 75%; }
    .split-view.split-initialized .handle:before {
      content: '';
      background: #fff;
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      left: 15px; }
    .split-view.split-initialized .handle:after {
      width: 12px;
      height: 48px;
      content: '';
      border-radius: 8px;
      background: #82bc49;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -24px;
      margin-left: -6px; }
  .split-view.split-initialized figure {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .split-view.split-initialized figure:nth-of-type(1):before, .split-view.split-initialized figure:nth-of-type(2):before {
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 4px;
      padding: 4px 8px; }
    .split-view.split-initialized figure:nth-of-type(1) {
      z-index: 1;
      right: 25%; }
      .split-view.split-initialized figure:nth-of-type(1):before {
        content: 'Before';
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 4px;
        left: 4px;
        padding: 4px 8px; }
    .split-view.split-initialized figure:nth-of-type(2) {
      z-index: 0; }
      .split-view.split-initialized figure:nth-of-type(2):before {
        content: 'After';
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        right: 4px;
        bottom: 4px;
        padding: 4px 8px; }
    .split-view.split-initialized figure img {
      max-width: none;
      pointer-events: none; }
  .split-view.split-initialized:not(.no-ease) .handle,
  .split-view.split-initialized:not(.no-ease) figure {
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    transition: all 250ms ease; }

.video-js .vjs-big-play-button {
  height: 0;
  border: none;
  background-color: none; }
  .video-js .vjs-big-play-button:before {
    font-size: 4em;
    line-height: .25;
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    content: "";
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .video-js .vjs-big-play-button:hover:before {
    opacity: .5; }

.copy__video {
  padding: 32px; }
  .copy__video div > * {
    text-align: left; }

@media screen and (max-width: 65.5625em) {
  img {
    max-width: 100%; }
  figure {
    margin: 0; }
  article.featured {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    article.featured::after {
      clear: both;
      content: "";
      display: table; }
    article.featured > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 95.6321184202%; }
      article.featured > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    article.featured > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 2.1839407899%; }
    article.featured > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    article.featured > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    article.featured > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    article.featured figure img {
      border-radius: 12px; }
  article > :last-child {
    margin-bottom: 0; }
  p + a.button {
    margin-top: 15px; }
  a.button + a.button {
    margin-top: 15px; }
  div.button-container + div.button-container {
    margin-top: 0; }
  .button {
    margin-right: 0; } }

@media screen and (max-width: 65.5625em) {
  .banner .banner__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto;
    padding: 80px 0; }
    .banner .banner__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .banner .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 91.2642368404%; }
      .banner .banner__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .banner .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 4.3678815798%; }
    .banner .banner__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .banner .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .banner .banner__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .banner .banner__wrapper h1 {
      font-size: 2.5rem; }
  .banner.banner--center .banner__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .banner.banner--center .banner__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 91.2642368404%; }
      .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 4.3678815798%; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .banner.banner--center .banner__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; } }

@media screen and (min-width: 26.5625em) and (max-width: 65.5625em) {
  .banner__wrapper {
    position: relative;
    min-height: 581.25px;
    padding: 80px 0;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .banner__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 82.9403914003%; }
      .banner__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 8.5298042998%; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .banner__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .banner__wrapper > div {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); }
    .banner__wrapper h1 {
      margin-top: 1em; } }

@media screen and (min-width: 65.5625em) {
  .banner__wrapper {
    position: relative;
    min-height: 775px;
    padding: 160px 0;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .banner__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 57.3509785009%; }
      .banner__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 8.5298042998%; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .banner__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .banner__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .banner__wrapper > div {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); }
    .banner__wrapper h1 {
      margin-top: 1em; } }

@media screen and (max-width: 65.5625em) {
  .copy {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 40px 0; }
    .copy .copy__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy .copy__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy .copy__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy .copy__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .copy.copy--center .copy__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy.copy--center .copy__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy.copy--center .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy.copy--center .copy__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .copy.copy--center .copy__summary:before, .copy.copy--center .copy__summary:after {
      margin-left: 0; }
    .copy.copy--center.copy--full .copy__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy.copy--center.copy--full .copy__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .copy.copy--center.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy.copy--center.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy.copy--center.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy.copy--center.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy.copy--center.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy.copy--center.copy--full .copy__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .copy.copy--two-col .copy__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy.copy--two-col .copy__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .copy.copy--two-col .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy.copy--two-col .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy.copy--two-col .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy.copy--two-col .copy__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy.copy--two-col .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy.copy--two-col .copy__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .copy.copy--full .copy__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy.copy--full .copy__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .copy.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy.copy--full .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy.copy--full .copy__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .copy .copy__summary {
      margin-top: 44px;
      margin-bottom: 44px; }
      .copy .copy__summary:before, .copy .copy__summary:after {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px; }
    .copy .copy__gallery figure {
      margin-bottom: 13px; }
    .copy .copy__gallery.copy__gallery--two-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy .copy__gallery.copy__gallery--two-col::after {
        clear: both;
        content: "";
        display: table; }
      .copy .copy__gallery.copy__gallery--two-col > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 10px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy .copy__gallery.copy__gallery--two-col > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy .copy__gallery.copy__gallery--two-col > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy .copy__gallery.copy__gallery--two-col > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy .copy__gallery.copy__gallery--two-col > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy .copy__gallery.copy__gallery--two-col > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .copy .copy__gallery.copy__gallery--two-col > :nth-child(2n+1) {
        text-align: right; }
      .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide::after {
          clear: both;
          content: "";
          display: table; }
        .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 10px;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 95.6321184202%; }
          .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 2.1839407899%; }
        .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .copy .copy__gallery.copy__gallery--two-col.copy__gallery--wide > *:not(style):not(script):last-child {
          margin-bottom: 0; }
    .copy .copy__gallery.copy__gallery--three-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .copy .copy__gallery.copy__gallery--three-col::after {
        clear: both;
        content: "";
        display: table; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 10px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .copy .copy__gallery.copy__gallery--three-col > *:not(style):not(script):last-child {
        margin-bottom: 0; } }

@media screen and (max-width: 65.5625em) {
  .cta .cta__wrapper {
    padding: 80px 32px; } }

@media (max-width: 65.5625em) {
  .cta .cta__form input[type='email'] {
    width: 100%;
    margin-right: 0; }
  .cta .cta__form .button {
    margin-top: 15px; } }

@media screen and (min-width: 26.5625em) and (max-width: 65.5625em) {
  .cta .cta__wrapper {
    padding: 125px 16px; } }

@media (min-width: 1200px) {
  .cta .cta__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .cta .cta__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .cta .cta__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 74.4105871005%; }
      .cta .cta__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .cta .cta__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 12.7947064497%; }
    .cta .cta__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .cta .cta__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .cta .cta__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; } }

@media (min-width: 1400px) {
  .cta .cta__form input[type='email'] {
    margin-right: 90px; } }

@media screen and (max-width: 65.5625em) {
  .image {
    margin-top: 32px;
    margin-bottom: 32px; }
    .image img {
      height: auto; }
    .image .image__wrapper {
      padding-right: 0;
      padding-left: 0; }
    .image.image--alt-bg {
      background-color: #ce251e; }
    .image.image--full.image--left .image__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .image.image--full.image--left .image__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .image.image--full.image--left .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .image.image--full.image--left .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .image.image--full.image--left .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .image.image--full.image--left .image__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .image.image--full.image--left .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .image.image--full.image--left .image__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .image.image--full.image--left .image__wrapper > figure {
        position: relative; }
    .image.image--full.image--left.image--halves .image__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .image.image--full.image--left.image--halves .image__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .image.image--full.image--left.image--halves .image__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .image.image--full.image--right .image__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .image.image--full.image--right .image__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .image.image--full.image--right .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .image.image--full.image--right .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .image.image--full.image--right .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .image.image--full.image--right .image__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .image.image--full.image--right .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .image.image--full.image--right .image__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .image.image--full.image--right .image__wrapper > figure {
        position: relative; }
    .image.image--full.image--right.image--halves .image__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .image.image--full.image--right.image--halves .image__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .image.image--full.image--right.image--halves .image__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .image.image--left:not(.image--halves) .image__wrapper, .image.image--left.image--halves .image__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .image.image--left:not(.image--halves) .image__wrapper::after, .image.image--left.image--halves .image__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1), .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 82.5284736809%; }
        .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child, .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1), .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 8.7357631596%; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n), .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1), .image.image--left.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .image.image--left:not(.image--halves) .image__wrapper > *:not(style):not(script):last-child, .image.image--left.image--halves .image__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .image.image--right:not(.image--halves) .image__wrapper, .image.image--right.image--halves .image__wrapper {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .image.image--right:not(.image--halves) .image__wrapper::after, .image.image--right.image--halves .image__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1), .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 40px;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 82.5284736809%; }
        .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child, .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1), .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 8.7357631596%; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n), .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):nth-child(1n+1), .image.image--right.image--halves .image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .image.image--right:not(.image--halves) .image__wrapper > *:not(style):not(script):last-child, .image.image--right.image--halves .image__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .image.image--right:not(.image--halves) .image__wrapper figure img, .image.image--right.image--halves .image__wrapper figure img {
        float: none; } }

@media screen and (max-width: 65.5625em) {
  .intro .intro__wrapper {
    text-align: center;
    padding: 40px 0;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .intro .intro__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 82.5284736809%; }
      .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 8.7357631596%; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .intro .intro__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .intro .intro__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; } }

@media screen and (max-width: 65.5625em) {
  .feed {
    margin-top: 16px;
    margin-bottom: 16px; }
    .feed .feed__wrapper {
      padding: 40px 16px; }
      .feed .feed__wrapper .feed__intro {
        margin-bottom: 20px;
        text-align: center; }
        .feed .feed__wrapper .feed__intro > * {
          width: 100% !important;
          margin: 0 !important; }
      .feed .feed__wrapper .feed__items .feed__item {
        margin-top: 16px;
        margin-bottom: 16px;
        padding-top: 20px;
        padding-bottom: 20px; }
      .feed .feed__wrapper .feed__items[class*='-col'] figure {
        display: block; }
      .feed .feed__wrapper .feed__items.feed__items--two-col {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .feed .feed__wrapper .feed__items.feed__items--two-col::after {
          clear: both;
          content: "";
          display: table; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 91.2642368404%; }
          .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 4.3678815798%; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .feed .feed__wrapper .feed__items.feed__items--two-col > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .feed .feed__wrapper .feed__items.feed__items--three-col {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .feed .feed__wrapper .feed__items.feed__items--three-col::after {
          clear: both;
          content: "";
          display: table; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 91.2642368404%; }
          .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 4.3678815798%; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .feed .feed__wrapper .feed__items.feed__items--three-col > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .feed .feed__wrapper .feed__items.feed__items--four-col {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .feed .feed__wrapper .feed__items.feed__items--four-col::after {
          clear: both;
          content: "";
          display: table; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 91.2642368404%; }
          .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 4.3678815798%; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .feed .feed__wrapper .feed__items.feed__items--four-col > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .feed .feed__wrapper .feed__items.feed__items--five-col {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .feed .feed__wrapper .feed__items.feed__items--five-col::after {
          clear: both;
          content: "";
          display: table; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 91.2642368404%; }
          .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 4.3678815798%; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .feed .feed__wrapper .feed__items.feed__items--five-col > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .feed .feed__wrapper .feed__items:not([class*='-col']) {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .feed .feed__wrapper .feed__items:not([class*='-col'])::after {
          clear: both;
          content: "";
          display: table; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 91.2642368404%; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 4.3678815798%; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) > *:not(style):not(script):last-child {
          margin-bottom: 0; }
        .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image {
          max-width: 1620px;
          margin-left: auto;
          margin-right: auto; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image::after {
            clear: both;
            content: "";
            display: table; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(1n+1) {
            margin-bottom: 0;
            float: left;
            display: block;
            margin-right: 4.8291579146%;
            width: 100%; }
            .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(1n+1):last-child {
              margin-right: 0; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(1n+1) {
            margin-right: 0; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(1n) {
            margin-right: 0; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):nth-child(1n+1) {
            clear: both; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image > *:not(style):not(script):last-child {
            margin-bottom: 0; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image figure + div {
            margin-top: 40px; }
          .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image figure:after {
            width: 200px;
            margin-left: 0; } }

@media screen and (min-width: 26.5625em) and (max-width: 65.5625em) {
  .feed .feed__wrapper .feed__intro {
    margin-bottom: 40px;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .feed .feed__wrapper .feed__intro::after {
      clear: both;
      content: "";
      display: table; }
    .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0.5em;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 82.9403914003%; }
      .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 8.5298042998%; }
    .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .feed .feed__wrapper .feed__intro > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .feed .feed__wrapper .feed__intro > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item:not(.feed__item--image):not(:last-child):after {
    width: 50%;
    margin-left: 0; }
  .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image:not(:last-child):after {
    width: 50%;
    margin-left: 0; } }

@media screen and (min-width: 65.5625em) {
  .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item:not(.feed__item--image):not(:last-child):after {
    width: 23.2317613015%;
    margin-left: 0; }
  .feed .feed__wrapper .feed__items:not([class*='-col']) .feed__item.feed__item--image:not(:last-child):after {
    width: 23.2317613015%;
    margin-left: 0; } }

@media screen and (max-width: 65.5625em) {
  .quote {
    margin-top: 16px;
    margin-bottom: 16px; }
    .quote .quote__wrapper {
      font-size: 1.375rem;
      padding: 60px 8px;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .quote .quote__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 82.5284736809%; }
        .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 8.7357631596%; }
      .quote .quote__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .quote .quote__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .quote .quote__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .quote .quote__wrapper footer {
        margin-top: 2em; } }

@media screen and (max-width: 65.5625em) {
  .callout {
    background-position: center;
    background-size: cover;
    margin-top: 0;
    margin-bottom: 16px; }
    .callout .callout__wrapper {
      background-position: center;
      background-size: cover;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .callout .callout__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 86.8963552607%; }
        .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 6.5518223697%; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .callout .callout__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .callout .callout__wrapper h1 {
        font-size: 2.5rem; }
      .callout .callout__wrapper > div {
        padding-right: 0;
        padding-left: 0; }
    .callout.callout--alt-bg.callout--full {
      background-color: #ce251e; }
    .callout.callout--alt-bg:not(.callout--full) .callout__wrapper {
      background-color: #ce251e; }
    .callout.callout--center {
      text-align: center; }
      .callout.callout--center .callout__wrapper {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .callout.callout--center .callout__wrapper::after {
          clear: both;
          content: "";
          display: table; }
        .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 82.5284736809%; }
          .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 8.7357631596%; }
        .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .callout.callout--center .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .callout.callout--center .callout__wrapper > *:not(style):not(script):last-child {
          margin-bottom: 0; } }

@media screen and (max-width: 65.5625em) {
  .signup .signup__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .signup .signup__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 95.6321184202%; }
      .signup .signup__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 2.1839407899%; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .signup .signup__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .signup .signup__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .signup .signup__wrapper h1 {
      font-size: 2.5rem; }
    .signup .signup__wrapper .form__address {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .signup .signup__wrapper .form__address::after {
        clear: both;
        content: "";
        display: table; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .signup .signup__wrapper .form__address > *:not(style):not(script):last-child {
        margin-bottom: 0; } }

@media screen and (max-width: 65.5625em) {
  #ROI {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    #ROI::after {
      clear: both;
      content: "";
      display: table; }
    #ROI > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 100%; }
      #ROI > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    #ROI > *:not(style):not(script):nth-child(1n+1) {
      margin-right: 0; }
    #ROI > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    #ROI > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    #ROI > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    #ROI .output {
      text-align: right;
      border-left: none; } }

@media screen and (min-width: 65.5625em) {
  .map .map__wrapper {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .map .map__wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .map .map__wrapper > *:not(style):not(script):nth-child(2n+1) {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .map .map__wrapper > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .map .map__wrapper > *:not(style):not(script):nth-child(2n+2) {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .map .map__wrapper > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .map .map__wrapper > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .map .map__wrapper > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .map .map__wrapper > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .map .map__wrapper > *:not(style):not(script):last-child {
      margin-bottom: 0; } }

@media screen and (max-width: 65.5625em) {
  .contact-form {
    margin-top: 32px;
    margin-bottom: 32px; }
    .contact-form .copy__wrapper {
      padding-right: 0;
      padding-left: 0;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .contact-form .copy__wrapper::after {
        clear: both;
        content: "";
        display: table; }
      .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 95.6321184202%; }
        .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 2.1839407899%; }
      .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .contact-form .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .contact-form .copy__wrapper > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .contact-form .copy__wrapper .form__inputs.form__inputs--two-col {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col::after {
          clear: both;
          content: "";
          display: table; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 4.8291579146%;
          width: 100%; }
          .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1):last-child {
            margin-right: 0; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
          margin-left: 0%; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
          margin-right: 0; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n) {
          margin-right: 0; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
        .contact-form .copy__wrapper .form__inputs.form__inputs--two-col > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .contact-form .copy__wrapper form > input,
      .contact-form .copy__wrapper form > label {
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 91.2642368404%; }
        .contact-form .copy__wrapper form > input:last-child,
        .contact-form .copy__wrapper form > label:last-child {
          margin-right: 0; }
        .contact-form .copy__wrapper form > input:nth-child(2n),
        .contact-form .copy__wrapper form > label:nth-child(2n) {
          margin-right: 0; }
        .contact-form .copy__wrapper form > input:nth-child(2n+1),
        .contact-form .copy__wrapper form > label:nth-child(2n+1) {
          clear: left; }
      .contact-form .copy__wrapper select {
        height: 52px; }
      .contact-form .copy__wrapper form > textarea {
        margin-top: 1em;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 100%; }
        .contact-form .copy__wrapper form > textarea:last-child {
          margin-right: 0; }
        .contact-form .copy__wrapper form > textarea:nth-child(2n) {
          margin-right: 0; }
        .contact-form .copy__wrapper form > textarea:nth-child(2n+1) {
          clear: left; } }

@media (min-width: 65.5625em) {
  .freeform-column.radio_group::after {
    clear: both;
    content: "";
    display: table; }
  .freeform-column.radio_group label:not(.freeform-label) {
    display: inline-block;
    float: left;
    width: 48.5%;
    margin-right: 3%; }
    .freeform-column.radio_group label:not(.freeform-label):nth-child(2n) {
      margin-right: 0; } }

@media screen and (max-width: 65.5625em) {
  input,
  textarea {
    font-size: 1rem;
    outline: none;
    margin-top: 16px;
    margin-bottom: 16px; }
    input[type='radio'],
    textarea[type='radio'] {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 42px;
      vertical-align: middle;
      -webkit-border-radius: 42px;
      margin: 8px 12px 8px 8px;
      -webkit-border-color: #f4f4f4;
      -webkit-appearance: none; }
      input[type='radio']:before, input[type='radio']:after,
      textarea[type='radio']:before,
      textarea[type='radio']:after {
        display: block;
        content: '';
        border-radius: 63px; }
      input[type='radio']:before,
      textarea[type='radio']:before {
        border: 10px solid #f4f4f4;
        background-color: #f4f4f4;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px; }
      input[type='radio']:after,
      textarea[type='radio']:after {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        -webkit-transition: all 250ms ease;
        -moz-transition: all 250ms ease;
        transition: all 250ms ease; }
  textarea {
    max-height: 200px; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']),
  textarea {
    padding: 14px 20.6666666667px;
    margin-top: 8px;
    margin-bottom: 8px; } }

@media screen and (max-width: 65.5625em) {
  header {
    background-size: 100% 87px; }
    header .header__wrapper {
      position: relative;
      padding-top: 0;
      padding-bottom: 0; }
      header .header__wrapper button.menu-toggle {
        right: 2em;
        display: block;
        width: 26px;
        height: 23px;
        padding: 0 !important;
        border: 0;
        border-radius: 0;
        background: transparent;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); }
        header .header__wrapper button.menu-toggle > span {
          display: block;
          width: 26px;
          max-width: 26px;
          height: 3px;
          margin-right: 0;
          background-color: #000;
          -webkit-transition: all 250ms ease;
          -moz-transition: all 250ms ease;
          transition: all 250ms ease;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%); }
          header .header__wrapper button.menu-toggle > span:before, header .header__wrapper button.menu-toggle > span:after {
            display: block;
            height: 3px;
            content: '';
            background-color: #000;
            -webkit-transition: all 250ms ease;
            -moz-transition: all 250ms ease;
            transition: all 250ms ease; }
          header .header__wrapper button.menu-toggle > span:before {
            position: absolute;
            top: -10px;
            right: 0;
            left: 0; }
          header .header__wrapper button.menu-toggle > span:after {
            position: absolute;
            top: 10px;
            right: 0;
            left: 0; }
        header .header__wrapper button.menu-toggle.close > span {
          background-color: transparent; }
          header .header__wrapper button.menu-toggle.close > span:before {
            top: 0;
            -webkit-transform: rotateZ(45deg);
            -moz-transform: rotateZ(45deg);
            -ms-transform: rotateZ(45deg);
            -o-transform: rotateZ(45deg);
            transform: rotateZ(45deg); }
          header .header__wrapper button.menu-toggle.close > span:after {
            top: 0;
            -webkit-transform: rotateZ(-45deg);
            -moz-transform: rotateZ(-45deg);
            -ms-transform: rotateZ(-45deg);
            -o-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg); }
        header .header__wrapper button.menu-toggle.back > span {
          width: 26px;
          background-color: #fff; }
          header .header__wrapper button.menu-toggle.back > span:before {
            top: 3px;
            right: 50%;
            left: -5px;
            border-top-left-radius: 6px;
            -webkit-transform: rotateZ(35deg);
            -moz-transform: rotateZ(35deg);
            -ms-transform: rotateZ(35deg);
            -o-transform: rotateZ(35deg);
            transform: rotateZ(35deg); }
          header .header__wrapper button.menu-toggle.back > span:after {
            top: -3px;
            right: 50%;
            left: -5px;
            border-bottom-left-radius: 6px;
            -webkit-transform: rotateZ(-35deg);
            -moz-transform: rotateZ(-35deg);
            -ms-transform: rotateZ(-35deg);
            -o-transform: rotateZ(-35deg);
            transform: rotateZ(-35deg); }
      header .header__wrapper > nav {
        z-index: 100;
        width: 100%;
        text-align: center;
        background-color: #f4f4f4;
        position: absolute;
        top: 94px;
        left: -100%;
        -webkit-transition: all 250ms ease;
        -moz-transition: all 250ms ease;
        transition: all 250ms ease;
        letter-spacing: 0.14em; }
        header .header__wrapper > nav.active {
          left: 0; }
        header .header__wrapper > nav a {
          display: block;
          width: 100%;
          border-radius: 0;
          padding-top: 16px;
          padding-bottom: 16px; }
        header .header__wrapper > nav .nav__wrapper {
          position: initial;
          width: 100%; }
          header .header__wrapper > nav .nav__wrapper a {
            margin: 0; }
            header .header__wrapper > nav .nav__wrapper a.button {
              padding-top: 16px;
              padding-bottom: 16px; }
          header .header__wrapper > nav .nav__wrapper.active > a {
            width: 100%;
            background-color: #f4f4f4;
            padding: 12px 16px;
            position: absolute;
            top: 0;
            left: 100%; }
          header .header__wrapper > nav .nav__wrapper.active > ul {
            left: 100%;
            margin-top: 52px; }
          header .header__wrapper > nav .nav__wrapper > div {
            display: block;
            width: auto; }
            header .header__wrapper > nav .nav__wrapper > div > ul {
              width: 100%;
              text-align: center;
              position: absolute;
              top: 0;
              left: -100%;
              -webkit-transition: all 250ms ease;
              -moz-transition: all 250ms ease;
              transition: all 250ms ease; }
              header .header__wrapper > nav .nav__wrapper > div > ul:before {
                display: none; }
              header .header__wrapper > nav .nav__wrapper > div > ul li {
                max-height: 46px;
                opacity: 1;
                background-color: #f4f4f4; }
                header .header__wrapper > nav .nav__wrapper > div > ul li.parent {
                  display: block; }
                  header .header__wrapper > nav .nav__wrapper > div > ul li.parent > a {
                    text-transform: uppercase; }
                header .header__wrapper > nav .nav__wrapper > div > ul li a {
                  line-height: 1.9; }
  .sub-nav {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .sub-nav::after {
      clear: both;
      content: "";
      display: table; }
    .sub-nav > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 91.2642368404%; }
      .sub-nav > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    .sub-nav > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 4.3678815798%; }
    .sub-nav > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    .sub-nav > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    .sub-nav > *:not(style):not(script):last-child {
      margin-bottom: 0; }
  footer .footer__wrapper nav > * {
    display: block;
    margin: 8px 16px;
    padding-top: 8px;
    padding-bottom: 8px; }
    footer .footer__wrapper nav > * a {
      display: inline-block; } }

@media screen and (max-width: 65.5625em) {
  header {
    border-bottom: 13px solid #fff; }
    header .header__wrapper {
      padding-top: 24px;
      padding-bottom: 16px; }
      header .header__wrapper > *:not(.menu-toggle) {
        display: inline-block;
        width: auto;
        padding: 0; }
      header .header__wrapper .header-logo {
        height: 54px; }
      header .header__wrapper nav.active {
        width: 100%; }
      header .header__wrapper nav > a:not(:last-of-type) {
        border-bottom: 1px solid #f4f4f4; } }

@media screen and (max-width: 65.5625em) {
  .footer .footer__wrapper > * > span {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px; }
  .footer .footer__wrapper > * a {
    color: #fff; } }

@media screen and (max-width: 65.5625em) {
  #mc_embed_signup {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    #mc_embed_signup::after {
      clear: both;
      content: "";
      display: table; }
    #mc_embed_signup > *:not(style):not(script):nth-child(1n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 4.8291579146%;
      width: 96.5056947362%; }
      #mc_embed_signup > *:not(style):not(script):nth-child(1n+1):last-child {
        margin-right: 0; }
    #mc_embed_signup > *:not(style):not(script):nth-child(1n+1) {
      margin-left: 1.7471526319%; }
    #mc_embed_signup > *:not(style):not(script):nth-child(1n) {
      margin-right: 0; }
    #mc_embed_signup > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
    #mc_embed_signup > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    #mc_embed_signup form .form__inputs.form__inputs--two-col {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col::after {
        clear: both;
        content: "";
        display: table; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 4.8291579146%;
        width: 100%; }
        #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
        margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      #mc_embed_signup form .form__inputs.form__inputs--two-col > *:not(style):not(script):last-child {
        margin-bottom: 0; } }

@media screen and (max-width: 65.5625em) {
  .dk-select {
    margin-bottom: 16px; } }

@media screen and (min-width: 26.5625em) and (max-width: 65.5625em) {
  .slick-slider .slick-prev {
    border-width: 40px 40px 40px 0; }
  .slick-slider .slick-next {
    border-width: 40px 0 40px 40px; } }

@media screen and (max-width: 65.5625em) {
  .slick-slider .slick-prev {
    border-width: 30px 30px 30px 0; }
  .slick-slider .slick-next {
    border-width: 30px 0 30px 30px; } }

.member-page {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  .member-page::after {
    clear: both;
    content: "";
    display: table; }
  .member-page .member-page__headshot {
    display: block;
    width: 138px;
    height: 138px;
    margin: 0 auto;
    border-radius: 50%; }
  .member-page .member-page__content h1 {
    margin: 0 0 22px 0; }
  .member-page .member-page__content h1 + hr {
    display: block;
    width: 107px;
    height: 6px;
    margin: 0 auto 31px auto;
    border: 0;
    background: #ffc400; }
  .member-page .member-page__header {
    text-align: center; }
  .member-page .member-page__title {
    margin-bottom: 70px; }
  .member-page .member-page__bio {
    font-weight: 400; }
  .member-page .health-system a {
    border-bottom: 0 !important; }
    .member-page .health-system a:hover, .member-page .health-system a:active {
      border-bottom: 0 !important; }
  .member-page .health-system__image {
    margin-bottom: 41px; }
  .member-page .health-system__details {
    margin-bottom: 32px; }
    .member-page .health-system__details .title {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 30px; }
    .member-page .health-system__details .location {
      font-weight: 600;
      line-height: 19px; }
  .member-page .health-system__description {
    font-weight: 400; }
  @media (max-width: 768px) {
    .member-page {
      padding: 50px 16px 100px; }
      .member-page .member-page__image-container {
        margin-bottom: 40px; }
      .member-page .member-page__sidebar {
        margin-top: 92px; } }
  @media (min-width: 768px) {
    .member-page .member-page__headshot {
      width: 240px;
      max-width: 100%;
      height: auto; }
    .member-page .member-page__content h1 {
      margin-bottom: 25px; }
    .member-page .member-page__content h1 + hr {
      width: 66%;
      margin: 0 0 25px 0; }
    .member-page .member-page__header {
      text-align: left; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .member-page {
      padding: 85px 32px 150px;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .member-page::after {
        clear: both;
        content: "";
        display: table; }
      .member-page > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 91.4701957002%; }
        .member-page > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .member-page > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 4.2649021499%; }
      .member-page > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .member-page > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .member-page > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .member-page .member-page__columns {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .member-page .member-page__columns::after {
          clear: both;
          content: "";
          display: table; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 23.2317613015%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          margin-left: 0%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 65.8807828007%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-left: 8.5298042998%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 65.8807828007%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3) {
          margin-left: 34.1192171993%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n) {
          margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          clear: both; }
        .member-page .member-page__columns > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .member-page .member-page__sidebar {
        margin-top: 92px; } }
  @media (min-width: 1200px) and (max-width: 1599px) {
    .member-page {
      padding: 115px 32px 237px; }
      .member-page .member-page__columns {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .member-page .member-page__columns::after {
          clear: both;
          content: "";
          display: table; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 18.9668591516%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          margin-left: 0%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 48.821174201%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-left: 4.2649021499%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 18.9668591516%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3) {
          margin-left: 4.2649021499%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n) {
          margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          clear: both; }
        .member-page .member-page__columns > *:not(style):not(script):last-child {
          margin-bottom: 0; } }
  @media (min-width: 1600px) {
    .member-page {
      padding: 135px 32px 267px; }
      .member-page .member-page__columns {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .member-page .member-page__columns::after {
          clear: both;
          content: "";
          display: table; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 23.2317613015%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          margin-left: 0%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 48.821174201%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+2) {
          margin-left: 0%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 14.7019570017%; }
          .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3):last-child {
            margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+3) {
          margin-left: 8.5298042998%; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n) {
          margin-right: 0; }
        .member-page .member-page__columns > *:not(style):not(script):nth-child(3n+1) {
          clear: both; }
        .member-page .member-page__columns > *:not(style):not(script):last-child {
          margin-bottom: 0; } }

.news-article {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  .news-article::after {
    clear: both;
    content: "";
    display: table; }
  .news-article h1:after {
    margin-right: 0;
    margin-left: 0; }
  .news-article .news-article__details hr {
    display: block;
    width: 100%;
    height: 12px;
    margin-top: 0;
    margin-bottom: 18px;
    border: 0;
    background: #edeaea; }
  .news-article .news-article__details .news-date {
    color: #000; }
  .news-article .news-article__details .news-type {
    margin-top: 13px; }
  .news-article .news-article__location {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 18px; }
  .news-article .news-article__featured-image {
    margin-bottom: 44px; }
    .news-article .news-article__featured-image figcaption {
      font-size: 1rem;
      font-weight: 400;
      line-height: 2;
      display: block;
      margin-top: 18px;
      color: #808080; }
  .news-article.no-image .news-article__body.has-location {
    margin-top: -.5em; }
  .news-article.no-image .news-article__body:not(.has-location) {
    margin-top: -.75em; }
  .news-article .news-article__meta {
    font-weight: 600; }
  .news-article .news-article__copy img + figcaption {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    display: block;
    margin-top: 18px;
    margin-bottom: 18px;
    color: #808080; }
  @media (max-width: 767px) {
    .news-article .news-article__intro {
      padding: 41px 16px 54px;
      text-align: center; }
      .news-article .news-article__intro h1:after {
        margin-right: auto;
        margin-left: auto; }
    .news-article .news-article__body-wrap {
      padding: 0 16px 72px; }
    .news-article .news-article__meta {
      margin-bottom: 65px;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-article .news-article__meta::after {
        clear: both;
        content: "";
        display: table; }
      .news-article .news-article__meta > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-article .news-article__meta > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .news-article .news-article__meta > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-article .news-article__meta > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .news-article .news-article__meta > *:not(style):not(script):nth-child(2n+2) {
        margin-right: 0; }
      .news-article .news-article__meta > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .news-article .news-article__meta > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .news-article .news-article__meta > *:not(style):not(script):last-child {
        margin-bottom: 0; }
      .news-article .news-article__meta .news-date,
      .news-article .news-article__meta .news-type {
        vertical-align: top; }
      .news-article .news-article__meta .news-type {
        margin-top: 0;
        text-align: right; }
    .news-article .news-article__location {
      margin-bottom: 10px; }
    .news-article .news-article__featured-image figcaption {
      margin-top: 16px; } }
  @media (min-width: 768px) and (max-width: 1399px) {
    .news-article .news-article__intro-wrap {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-article .news-article__intro-wrap::after {
        clear: both;
        content: "";
        display: table; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 82.9403914003%; }
        .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 8.5298042998%; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .news-article .news-article__intro {
      padding: 100px 0; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .news-article .news-article__body-wrap {
      padding-bottom: 128px;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-article .news-article__body-wrap::after {
        clear: both;
        content: "";
        display: table; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 10.4370548518%; }
        .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        margin-left: 8.5298042998%; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 61.6158806508%; }
        .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2) {
        margin-left: 0%; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .news-article .news-article__body-wrap > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .news-article .news-article__body-wrap {
      padding-bottom: 128px;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-article .news-article__body-wrap::after {
        clear: both;
        content: "";
        display: table; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 6.1721527019%; }
        .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        margin-left: 8.5298042998%; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 65.8807828007%; }
        .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2) {
        margin-left: 0%; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .news-article .news-article__body-wrap > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 1400px) {
    .news-article .news-article__intro-wrap {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-article .news-article__intro-wrap::after {
        clear: both;
        content: "";
        display: table; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 65.8807828007%; }
        .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 17.0596085997%; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .news-article .news-article__intro-wrap > *:not(style):not(script):last-child {
        margin-bottom: 0; }
    .news-article .news-article__intro {
      padding: 156px 0; }
    .news-article .news-article__body-wrap {
      padding-bottom: 187px;
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .news-article .news-article__body-wrap::after {
        clear: both;
        content: "";
        display: table; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 6.1721527019%; }
        .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1):last-child {
          margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        margin-left: 17.0596085997%; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2):last-child {
          margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+2) {
        margin-left: 0%; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n) {
        margin-right: 0; }
      .news-article .news-article__body-wrap > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
      .news-article .news-article__body-wrap > *:not(style):not(script):last-child {
        margin-bottom: 0; } }

.media-footer-wrap {
  background-color: #f4f4f4; }

.media-footer-wrap + .cta {
  margin-top: 0; }

.media-footer {
  padding: 60px 16px 73px;
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto; }
  .media-footer::after {
    clear: both;
    content: "";
    display: table; }
  .media-footer .media-footer__about {
    margin-bottom: 72px; }
  .media-footer h2.media-footer__headline {
    line-height: 36px; }
  @media (min-width: 640px) and (max-width: 978px) {
    .media-footer {
      max-width: 1620px;
      margin-left: auto;
      margin-right: auto; }
      .media-footer::after {
        clear: both;
        content: "";
        display: table; }
      .media-footer > *:not(style):not(script):nth-child(1n+1) {
        margin-bottom: 0;
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 82.9403914003%; }
        .media-footer > *:not(style):not(script):nth-child(1n+1):last-child {
          margin-right: 0; }
      .media-footer > *:not(style):not(script):nth-child(1n+1) {
        margin-left: 8.5298042998%; }
      .media-footer > *:not(style):not(script):nth-child(1n) {
        margin-right: 0; }
      .media-footer > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
      .media-footer > *:not(style):not(script):last-child {
        margin-bottom: 0; } }
  @media (min-width: 979px) {
    .media-footer {
      padding: 139px 32px 144px; }
      .media-footer .media-footer__columns {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; }
        .media-footer .media-footer__columns::after {
          clear: both;
          content: "";
          display: table; }
        .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+1) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 40.2913699012%; }
          .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+1):last-child {
            margin-right: 0; }
        .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+1) {
          margin-left: 0%; }
        .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+2) {
          margin-bottom: 0;
          float: left;
          display: block;
          margin-right: 2.3576515979%;
          width: 48.821174201%; }
          .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+2):last-child {
            margin-right: 0; }
        .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+2) {
          margin-left: 8.5298042998%; }
        .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n) {
          margin-right: 0; }
        .media-footer .media-footer__columns > *:not(style):not(script):nth-child(2n+1) {
          clear: both; }
        .media-footer .media-footer__columns > *:not(style):not(script):last-child {
          margin-bottom: 0; }
      .media-footer .media-footer__about {
        margin-bottom: 0; }
      .media-footer h2.media-footer__headline {
        line-height: 40px; } }

@media (min-width: 640px) {
  .media-contacts {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .media-contacts::after {
      clear: both;
      content: "";
      display: table; }
    .media-contacts > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .media-contacts > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .media-contacts > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .media-contacts > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .media-contacts > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .media-contacts > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .media-contacts > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .media-contacts > *:not(style):not(script):last-child {
      margin-bottom: 0; } }

.media-contact {
  font-size: 1rem;
  line-height: 32px;
  max-width: 460px;
  margin-bottom: 39px; }
  .media-contact .name {
    font-weight: 500; }
  .media-contact .organization {
    font-weight: 400; }
  .media-contact .numbers {
    margin-top: 15px; }
  .media-contact .number {
    margin-bottom: 10px;
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto; }
    .media-contact .number::after {
      clear: both;
      content: "";
      display: table; }
    .media-contact .number > *:not(style):not(script):nth-child(2n+1) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%; }
      .media-contact .number > *:not(style):not(script):nth-child(2n+1):last-child {
        margin-right: 0; }
    .media-contact .number > *:not(style):not(script):nth-child(2n+2) {
      margin-bottom: 0;
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 65.8807828007%; }
      .media-contact .number > *:not(style):not(script):nth-child(2n+2):last-child {
        margin-right: 0; }
    .media-contact .number > *:not(style):not(script):nth-child(2n+2) {
      margin-right: 0; }
    .media-contact .number > *:not(style):not(script):nth-child(2n) {
      margin-right: 0; }
    .media-contact .number > *:not(style):not(script):nth-child(2n+1) {
      clear: both; }
    .media-contact .number > *:not(style):not(script):last-child {
      margin-bottom: 0; }
    .media-contact .number strong {
      font-weight: 600; }
  .media-contact .email {
    margin-top: 1px; }
  @media (min-width: 979px) {
    .media-contact .name {
      font-size: 1.25rem; }
    .media-contact .number {
      font-size: 1.25rem;
      line-height: 40px; }
    .media-contact .numbers {
      margin-top: 30px; }
    .media-contact .email {
      font-size: 1.25rem;
      line-height: 40px;
      margin-top: 16px; } }

/*# sourceMappingURL=style.css.map */